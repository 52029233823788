import cx from 'classnames'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '~shared/components/Input/Checkbox/Checkbox'
import {
  MAINCONTRACTOR_ADMIN_ROLE,
  RoleType,
} from '~shared/hooks/gatekeeper/roles'
import { useMode } from '~shared/hooks/useMode'
import { useUser } from '~shared/services/firebase/auth/hooks'

import { MODULR_TERMS_AND_CONDITIONS_PATH } from '@/payments/consts/terms'
import { ModulrPaymentInfo } from '@/payments/hooks/useModulrPayments'

interface Props {
  info: ModulrPaymentInfo
  onSuccess?: () => void
}

const ALLOWED_ROLES: RoleType[] = [MAINCONTRACTOR_ADMIN_ROLE]

const ModulrTermsCheckbox = ({ info, onSuccess: onConfirm }: Props) => {
  const user = useUser()
  const mode = useMode()

  const { disabled, signed } = React.useMemo(() => {
    const signed = info.hasSignedTerms
    const isWap = mode === 'WAP'
    const isMcpAdmin =
      user?.roles?.some((r) => r && ALLOWED_ROLES.includes(r.id as RoleType)) ??
      false
    return {
      disabled: isWap || !isMcpAdmin,
      signed,
    }
  }, [info.hasSignedTerms, mode, user?.roles])

  const { t } = useTranslation()

  return (
    <div className='flex flex-col items-start gap-2'>
      <div
        className={cx(
          'flex w-auto flex-row items-center rounded-lg border border-yellow-dark bg-yellow-light py-2 px-3',
          disabled ? 'opacity-75' : 'opacity-100',
        )}
      >
        <Checkbox
          testId='modulr-terms-checkbox'
          className='flex flex-row items-center'
          disabled={disabled || signed}
          checked={signed}
          onChange={() => {
            if (signed) return
            return info.acceptTerms(onConfirm)
          }}
        />
        <div className='text-sm text-black/90'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={MODULR_TERMS_AND_CONDITIONS_PATH}
            className='text-purple underline'
          >
            {t('mcp_settings.agree_to_modulr_tcs_link_message')}
          </a>
        </div>
      </div>
      {disabled && !signed ? (
        <p className='text-xs italic text-red-dark'>
          {t('mcp_settings.no_permission_modulr_tcs')}
        </p>
      ) : null}
    </div>
  )
}

export default ModulrTermsCheckbox
