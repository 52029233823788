import { publicRuntimeConfig } from './runtime'

const validAppEnvs = [
  'local',
  'dev',
  'qa',
  'demo',
  'staging',
  'production',
  'unknown',
  'test',
] as const
export type AppEnv = (typeof validAppEnvs)[number]

const validPortalRuntimes = ['MAINCONTRACTOR', 'NETWORK'] as const
export type PortalRuntime = (typeof validPortalRuntimes)[number]

const toAppEnv = (val: string | undefined): AppEnv => {
  if (!val) return 'unknown'
  if (validAppEnvs.includes(val as AppEnv)) return val as AppEnv
  return 'unknown'
}

const toPortalRuntime = (val: string | undefined): PortalRuntime => {
  if (
    typeof val === 'string' &&
    validPortalRuntimes.includes(val as PortalRuntime)
  )
    return val as PortalRuntime
  return 'MAINCONTRACTOR'
}

export const APP_ENV = toAppEnv(publicRuntimeConfig.environment)
export const PORTAL_RUNTIME = toPortalRuntime(publicRuntimeConfig.portalRuntime)
export const DEV_MODE =
  publicRuntimeConfig.developerMode === 'true' ||
  publicRuntimeConfig.developerMode === '1'
