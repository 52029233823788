import { FirebaseApp, getApp, initializeApp } from 'firebase/app'

import { firebaseClientConfig } from '~shared/config/firebase'

export const getFirebaseInstance = (): FirebaseApp => {
  try {
    return getApp()
  } catch (e) {
    if (!firebaseClientConfig)
      throw new Error(
        'The Firebase configuration is not available. Firebase cannot load.',
      )
    initializeApp(firebaseClientConfig)
    return getApp()
  }
}
