import { GetMainContractorBasicByIdDocument } from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'

import { rootApolloClient } from '~shared/services/apollo/apollo'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions & {
  allowedOnWap?: boolean
}

export const mcHasDeductionsEnabled = (
  options?: Options,
): MiddlewareHandler => ({
  fn: async ({ mainContractorId, mode }) => {
    if (mode === 'WAP' && options?.allowedOnWap) {
      return { status: 'ok' }
    }

    if (!isNonEmptyString(mainContractorId)) return { status: 'redirect' }

    const isDeductionsEnabled = (
      await rootApolloClient.query({
        query: GetMainContractorBasicByIdDocument,
        variables: { id: mainContractorId },
        fetchPolicy: 'cache-first',
      })
    ).data.mainContractor?.deductionEnabled

    return isDeductionsEnabled ? { status: 'ok' } : { status: 'redirect' }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
