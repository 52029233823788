export const mimeTypeToExtension = (mimeType: string) => {
  switch (mimeType) {
    case 'application/zip':
      return 'zip'
    case 'image/jpeg':
      return 'jpg'
    case 'image/png':
      return 'png'
    case 'image/gif':
      return 'gif'
    case 'image/svg+xml':
      return 'svg'
    case 'image/webp':
      return 'webp'
    case 'application/pdf':
      return 'pdf'
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return 'docx'
    case 'application/msword':
      return 'doc'
    case 'text/csv':
      return 'csv'
    case 'text/plain':
      return 'txt'
    default:
      return null
  }
}
