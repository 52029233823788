const isExists = <T>(item: T | undefined | null): item is Mandatory<T> =>
  Boolean(item)

export const getInitials = (firstname?: string, lastname?: string): string =>
  [firstname, lastname]
    .filter(isExists)
    .map((str) => str[0].toUpperCase())
    .join('') || '??'

export const isString = (item: unknown): item is string =>
  typeof item === 'string'

export const isNonEmptyString = (item: unknown): item is string =>
  isString(item) && item.length > 0

export const maskValue = (
  item: Optional<string>,
  mask: string,
): Optional<string> => {
  if (!item) return undefined
  // Newly added line below, as if the mask is empty, the replace fails during test
  if (!mask) return undefined
  // Newly added line below, as if the item is not a string, the replace fails during test
  if (!isString(item)) return undefined

  let i = 0
  const numbers = item.replace(/([^A-Za-z0-9])/g, '').split('')
  return mask.replace(/#/g, () => numbers[i++] ?? '').trim()
}
