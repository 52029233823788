import { ParsedUrlQuery } from 'querystring'

import { isNonEmptyString } from './string'

const INVALID_REDIRECT_ROUTES = ['/logout']

export const getRedirectRouteFromQueryParams = ({
  path,
}: ParsedUrlQuery): string | null =>
  isNonEmptyString(path) && !INVALID_REDIRECT_ROUTES.includes(path)
    ? path
    : null
