export type RoutePaths =
  | '/404'
  | '/auth/action'
  | '/auth/change-password'
  | '/auth/reset-password'
  | '/auth/verify'
  | '/compliance/driving-licence'
  | '/compliance/evidence'
  | '/customers/[id]'
  | '/customers'
  | '/debug/app-config'
  | '/debug/dev'
  | '/debug/feature-flags'
  | '/debug/maintenance'
  | '/drivers/[id]/engagement-details'
  | '/drivers/[id]/engagements/[mcId]'
  | '/drivers/[id]/financial-details'
  | '/drivers/[id]/incidents'
  | '/drivers/[id]'
  | '/drivers/[id]/payments/[summaryId]'
  | '/drivers/[id]/payments/create/[payrunId]'
  | '/drivers/[id]/vehicle'
  | '/drivers/active'
  | '/drivers/inactive'
  | '/drivers/onboarding'
  | '/evidence-template/edit/[id]'
  | '/evidence-template/labels'
  | '/evidence-template/new'
  | '/evidence-template/templates'
  | '/forgot-password'
  | '/'
  | '/knowledge-base/[...category]'
  | '/knowledge-base'
  | '/login'
  | '/logout'
  | '/main-contractors/[mcId]/carrier-settings'
  | '/main-contractors/[mcId]/depots'
  | '/main-contractors/[mcId]/document-library/archived'
  | '/main-contractors/[mcId]/document-library'
  | '/main-contractors/[mcId]/document-library/uploaded'
  | '/main-contractors/[mcId]/documents/[documentId]'
  | '/main-contractors/[mcId]/driving-licence-checks'
  | '/main-contractors/[mcId]/import-drivers'
  | '/main-contractors/[mcId]'
  | '/main-contractors/[mcId]/onboarding'
  | '/main-contractors/[mcId]/payments/details'
  | '/main-contractors/[mcId]/payments'
  | '/main-contractors/[mcId]/payments/payment-caps'
  | '/main-contractors/[mcId]/rate-cards/configurator'
  | '/main-contractors/[mcId]/rate-cards'
  | '/main-contractors/[mcId]/users'
  | '/main-contractors/archived'
  | '/main-contractors/inactive'
  | '/main-contractors'
  | '/main-contractors/invite'
  | '/main-contractors/onboarding'
  | '/onboarding/about-wise'
  | '/onboarding/about-your-business'
  | '/onboarding/checking-details'
  | '/onboarding/contracts-and-policies'
  | '/onboarding/create-account'
  | '/onboarding/finished'
  | '/onboarding/protecting-your-business'
  | '/onboarding/set-up-steps'
  | '/onboarding/welcome'
  | '/payments/[mcId]/[depotId]'
  | '/payments/closed'
  | '/payments/completed'
  | '/payments/depot/[depotId]'
  | '/payments/in-progress'
  | '/payments'
  | '/payments/payrun/[id]'
  | '/payments/submitted'
  | '/reports/awaiting-activation'
  | '/reports/deductions-overview'
  | '/reports/driver-depots'
  | '/reports'
  | '/reports/signed-policies'
  | '/reports/slas'
  | '/reports/unresponsive-applications'
  | '/reports/utr-report'
  | '/resource/direct/available'
  | '/resource/direct/offboarded'
  | '/resource/direct/onboarding'
  | '/resource/partners/available'
  | '/resource/partners/disengaged'
  | '/resource/partners/onboarding'
  | '/scheduling/[mcId]/[depotId]'
  | '/scheduling/[mcId]/[depotId]/start-times'
  | '/scheduling'
  | '/scheduling/route-library/[mcId]/[depotId]'
  | '/settings/carrier-settings'
  | '/settings/document-library'
  | '/settings/driving-licence-checks'
  | '/settings'
  | '/settings/payment-caps'
  | '/settings/payments'
  | '/settings/rate-cards'
  | '/settings/users'
  | '/users'
  | '/worklog/[id]/[locationId]/history'
  | '/worklog/[id]/history'
  | '/worklog'

export function route(name: '/404'): `/404`
export function route(name: '/auth/action'): `/auth/action`
export function route(name: '/auth/change-password'): `/auth/change-password`
export function route(name: '/auth/reset-password'): `/auth/reset-password`
export function route(name: '/auth/verify'): `/auth/verify`
export function route(
  name: '/compliance/driving-licence',
): `/compliance/driving-licence`
export function route(name: '/compliance/evidence'): `/compliance/evidence`
export function route(
  name: '/customers/[id]',
  id: string,
): `/customers/${string}`
export function route(name: '/customers'): `/customers`
export function route(name: '/debug/app-config'): `/debug/app-config`
export function route(name: '/debug/dev'): `/debug/dev`
export function route(name: '/debug/feature-flags'): `/debug/feature-flags`
export function route(name: '/debug/maintenance'): `/debug/maintenance`
export function route(
  name: '/drivers/[id]/engagement-details',
  id: string,
): `/drivers/${string}/engagement-details`
export function route(
  name: '/drivers/[id]/engagements/[mcId]',
  id: string,
  mcId: string,
): `/drivers/${string}/engagements/${string}`
export function route(
  name: '/drivers/[id]/financial-details',
  id: string,
): `/drivers/${string}/financial-details`
export function route(
  name: '/drivers/[id]/incidents',
  id: string,
): `/drivers/${string}/incidents`
export function route(name: '/drivers/[id]', id: string): `/drivers/${string}`
export function route(
  name: '/drivers/[id]/payments/[summaryId]',
  id: string,
  summaryId: string,
): `/drivers/${string}/payments/${string}`
export function route(
  name: '/drivers/[id]/payments/create/[payrunId]',
  id: string,
  payrunId: string,
): `/drivers/${string}/payments/create/${string}`
export function route(
  name: '/drivers/[id]/vehicle',
  id: string,
): `/drivers/${string}/vehicle`
export function route(name: '/drivers/active'): `/drivers/active`
export function route(name: '/drivers/inactive'): `/drivers/inactive`
export function route(name: '/drivers/onboarding'): `/drivers/onboarding`
export function route(
  name: '/evidence-template/edit/[id]',
  id: string,
): `/evidence-template/edit/${string}`
export function route(
  name: '/evidence-template/labels',
): `/evidence-template/labels`
export function route(name: '/evidence-template/new'): `/evidence-template/new`
export function route(
  name: '/evidence-template/templates',
): `/evidence-template/templates`
export function route(name: '/forgot-password'): `/forgot-password`
export function route(name: '/'): `/`
export function route(
  name: '/knowledge-base/[...category]',
  category: string,
): `/knowledge-base/${string}`
export function route(name: '/knowledge-base'): `/knowledge-base`
export function route(name: '/login'): `/login`
export function route(name: '/logout'): `/logout`
export function route(
  name: '/main-contractors/[mcId]/carrier-settings',
  mcId: string,
): `/main-contractors/${string}/carrier-settings`
export function route(
  name: '/main-contractors/[mcId]/depots',
  mcId: string,
): `/main-contractors/${string}/depots`
export function route(
  name: '/main-contractors/[mcId]/document-library/archived',
  mcId: string,
): `/main-contractors/${string}/document-library/archived`
export function route(
  name: '/main-contractors/[mcId]/document-library',
  mcId: string,
): `/main-contractors/${string}/document-library`
export function route(
  name: '/main-contractors/[mcId]/document-library/uploaded',
  mcId: string,
): `/main-contractors/${string}/document-library/uploaded`
export function route(
  name: '/main-contractors/[mcId]/documents/[documentId]',
  mcId: string,
  documentId: string,
): `/main-contractors/${string}/documents/${string}`
export function route(
  name: '/main-contractors/[mcId]/driving-licence-checks',
  mcId: string,
): `/main-contractors/${string}/driving-licence-checks`
export function route(
  name: '/main-contractors/[mcId]/import-drivers',
  mcId: string,
): `/main-contractors/${string}/import-drivers`
export function route(
  name: '/main-contractors/[mcId]',
  mcId: string,
): `/main-contractors/${string}`
export function route(
  name: '/main-contractors/[mcId]/onboarding',
  mcId: string,
): `/main-contractors/${string}/onboarding`
export function route(
  name: '/main-contractors/[mcId]/payments/details',
  mcId: string,
): `/main-contractors/${string}/payments/details`
export function route(
  name: '/main-contractors/[mcId]/payments',
  mcId: string,
): `/main-contractors/${string}/payments`
export function route(
  name: '/main-contractors/[mcId]/payments/payment-caps',
  mcId: string,
): `/main-contractors/${string}/payments/payment-caps`
export function route(
  name: '/main-contractors/[mcId]/rate-cards/configurator',
  mcId: string,
): `/main-contractors/${string}/rate-cards/configurator`
export function route(
  name: '/main-contractors/[mcId]/rate-cards',
  mcId: string,
): `/main-contractors/${string}/rate-cards`
export function route(
  name: '/main-contractors/[mcId]/users',
  mcId: string,
): `/main-contractors/${string}/users`
export function route(
  name: '/main-contractors/archived',
): `/main-contractors/archived`
export function route(
  name: '/main-contractors/inactive',
): `/main-contractors/inactive`
export function route(name: '/main-contractors'): `/main-contractors`
export function route(
  name: '/main-contractors/invite',
): `/main-contractors/invite`
export function route(
  name: '/main-contractors/onboarding',
): `/main-contractors/onboarding`
export function route(name: '/onboarding/about-wise'): `/onboarding/about-wise`
export function route(
  name: '/onboarding/about-your-business',
): `/onboarding/about-your-business`
export function route(
  name: '/onboarding/checking-details',
): `/onboarding/checking-details`
export function route(
  name: '/onboarding/contracts-and-policies',
): `/onboarding/contracts-and-policies`
export function route(
  name: '/onboarding/create-account',
): `/onboarding/create-account`
export function route(name: '/onboarding/finished'): `/onboarding/finished`
export function route(
  name: '/onboarding/protecting-your-business',
): `/onboarding/protecting-your-business`
export function route(
  name: '/onboarding/set-up-steps',
): `/onboarding/set-up-steps`
export function route(name: '/onboarding/welcome'): `/onboarding/welcome`
export function route(
  name: '/payments/[mcId]/[depotId]',
  mcId: string,
  depotId: string,
): `/payments/${string}/${string}`
export function route(name: '/payments/closed'): `/payments/closed`
export function route(name: '/payments/completed'): `/payments/completed`
export function route(
  name: '/payments/depot/[depotId]',
  depotId: string,
): `/payments/depot/${string}`
export function route(name: '/payments/in-progress'): `/payments/in-progress`
export function route(name: '/payments'): `/payments`
export function route(
  name: '/payments/payrun/[id]',
  id: string,
): `/payments/payrun/${string}`
export function route(name: '/payments/submitted'): `/payments/submitted`
export function route(
  name: '/reports/awaiting-activation',
): `/reports/awaiting-activation`
export function route(
  name: '/reports/deductions-overview',
): `/reports/deductions-overview`
export function route(name: '/reports/driver-depots'): `/reports/driver-depots`
export function route(name: '/reports'): `/reports`
export function route(
  name: '/reports/signed-policies',
): `/reports/signed-policies`
export function route(name: '/reports/slas'): `/reports/slas`
export function route(
  name: '/reports/unresponsive-applications',
): `/reports/unresponsive-applications`
export function route(name: '/reports/utr-report'): `/reports/utr-report`
export function route(
  name: '/resource/direct/available',
): `/resource/direct/available`
export function route(
  name: '/resource/direct/offboarded',
): `/resource/direct/offboarded`
export function route(
  name: '/resource/direct/onboarding',
): `/resource/direct/onboarding`
export function route(
  name: '/resource/partners/available',
): `/resource/partners/available`
export function route(
  name: '/resource/partners/disengaged',
): `/resource/partners/disengaged`
export function route(
  name: '/resource/partners/onboarding',
): `/resource/partners/onboarding`
export function route(
  name: '/scheduling/[mcId]/[depotId]',
  mcId: string,
  depotId: string,
): `/scheduling/${string}/${string}`
export function route(
  name: '/scheduling/[mcId]/[depotId]/start-times',
  mcId: string,
  depotId: string,
): `/scheduling/${string}/${string}/start-times`
export function route(name: '/scheduling'): `/scheduling`
export function route(
  name: '/scheduling/route-library/[mcId]/[depotId]',
  mcId: string,
  depotId: string,
): `/scheduling/route-library/${string}/${string}`
export function route(
  name: '/settings/carrier-settings',
): `/settings/carrier-settings`
export function route(
  name: '/settings/document-library',
): `/settings/document-library`
export function route(
  name: '/settings/driving-licence-checks',
): `/settings/driving-licence-checks`
export function route(name: '/settings'): `/settings`
export function route(name: '/settings/payment-caps'): `/settings/payment-caps`
export function route(name: '/settings/payments'): `/settings/payments`
export function route(name: '/settings/rate-cards'): `/settings/rate-cards`
export function route(name: '/settings/users'): `/settings/users`
export function route(name: '/users'): `/users`
export function route(
  name: '/worklog/[id]/[locationId]/history',
  id: string,
  locationId: string,
): `/worklog/${string}/${string}/history`
export function route(
  name: '/worklog/[id]/history',
  id: string,
): `/worklog/${string}/history`
export function route(name: '/worklog'): `/worklog`
export function route(
  name:
    | '/404'
    | '/auth/action'
    | '/auth/change-password'
    | '/auth/reset-password'
    | '/auth/verify'
    | '/compliance/driving-licence'
    | '/compliance/evidence'
    | '/customers/[id]'
    | '/customers'
    | '/debug/app-config'
    | '/debug/dev'
    | '/debug/feature-flags'
    | '/debug/maintenance'
    | '/drivers/[id]/engagement-details'
    | '/drivers/[id]/engagements/[mcId]'
    | '/drivers/[id]/financial-details'
    | '/drivers/[id]/incidents'
    | '/drivers/[id]'
    | '/drivers/[id]/payments/[summaryId]'
    | '/drivers/[id]/payments/create/[payrunId]'
    | '/drivers/[id]/vehicle'
    | '/drivers/active'
    | '/drivers/inactive'
    | '/drivers/onboarding'
    | '/evidence-template/edit/[id]'
    | '/evidence-template/labels'
    | '/evidence-template/new'
    | '/evidence-template/templates'
    | '/forgot-password'
    | '/'
    | '/knowledge-base/[...category]'
    | '/knowledge-base'
    | '/login'
    | '/logout'
    | '/main-contractors/[mcId]/carrier-settings'
    | '/main-contractors/[mcId]/depots'
    | '/main-contractors/[mcId]/document-library/archived'
    | '/main-contractors/[mcId]/document-library'
    | '/main-contractors/[mcId]/document-library/uploaded'
    | '/main-contractors/[mcId]/documents/[documentId]'
    | '/main-contractors/[mcId]/driving-licence-checks'
    | '/main-contractors/[mcId]/import-drivers'
    | '/main-contractors/[mcId]'
    | '/main-contractors/[mcId]/onboarding'
    | '/main-contractors/[mcId]/payments/details'
    | '/main-contractors/[mcId]/payments'
    | '/main-contractors/[mcId]/payments/payment-caps'
    | '/main-contractors/[mcId]/rate-cards/configurator'
    | '/main-contractors/[mcId]/rate-cards'
    | '/main-contractors/[mcId]/users'
    | '/main-contractors/archived'
    | '/main-contractors/inactive'
    | '/main-contractors'
    | '/main-contractors/invite'
    | '/main-contractors/onboarding'
    | '/onboarding/about-wise'
    | '/onboarding/about-your-business'
    | '/onboarding/checking-details'
    | '/onboarding/contracts-and-policies'
    | '/onboarding/create-account'
    | '/onboarding/finished'
    | '/onboarding/protecting-your-business'
    | '/onboarding/set-up-steps'
    | '/onboarding/welcome'
    | '/payments/[mcId]/[depotId]'
    | '/payments/closed'
    | '/payments/completed'
    | '/payments/depot/[depotId]'
    | '/payments/in-progress'
    | '/payments'
    | '/payments/payrun/[id]'
    | '/payments/submitted'
    | '/reports/awaiting-activation'
    | '/reports/deductions-overview'
    | '/reports/driver-depots'
    | '/reports'
    | '/reports/signed-policies'
    | '/reports/slas'
    | '/reports/unresponsive-applications'
    | '/reports/utr-report'
    | '/resource/direct/available'
    | '/resource/direct/offboarded'
    | '/resource/direct/onboarding'
    | '/resource/partners/available'
    | '/resource/partners/disengaged'
    | '/resource/partners/onboarding'
    | '/scheduling/[mcId]/[depotId]'
    | '/scheduling/[mcId]/[depotId]/start-times'
    | '/scheduling'
    | '/scheduling/route-library/[mcId]/[depotId]'
    | '/settings/carrier-settings'
    | '/settings/document-library'
    | '/settings/driving-licence-checks'
    | '/settings'
    | '/settings/payment-caps'
    | '/settings/payments'
    | '/settings/rate-cards'
    | '/settings/users'
    | '/users'
    | '/worklog/[id]/[locationId]/history'
    | '/worklog/[id]/history'
    | '/worklog',
  arg0?: string,
  arg1?: string,
):
  | `/404`
  | `/auth/action`
  | `/auth/change-password`
  | `/auth/reset-password`
  | `/auth/verify`
  | `/compliance/driving-licence`
  | `/compliance/evidence`
  | `/customers/${string}`
  | `/customers`
  | `/debug/app-config`
  | `/debug/dev`
  | `/debug/feature-flags`
  | `/debug/maintenance`
  | `/drivers/${string}/engagement-details`
  | `/drivers/${string}/engagements/${string}`
  | `/drivers/${string}/financial-details`
  | `/drivers/${string}/incidents`
  | `/drivers/${string}`
  | `/drivers/${string}/payments/${string}`
  | `/drivers/${string}/payments/create/${string}`
  | `/drivers/${string}/vehicle`
  | `/drivers/active`
  | `/drivers/inactive`
  | `/drivers/onboarding`
  | `/evidence-template/edit/${string}`
  | `/evidence-template/labels`
  | `/evidence-template/new`
  | `/evidence-template/templates`
  | `/forgot-password`
  | `/`
  | `/knowledge-base/${string}`
  | `/knowledge-base`
  | `/login`
  | `/logout`
  | `/main-contractors/${string}/carrier-settings`
  | `/main-contractors/${string}/depots`
  | `/main-contractors/${string}/document-library/archived`
  | `/main-contractors/${string}/document-library`
  | `/main-contractors/${string}/document-library/uploaded`
  | `/main-contractors/${string}/documents/${string}`
  | `/main-contractors/${string}/driving-licence-checks`
  | `/main-contractors/${string}/import-drivers`
  | `/main-contractors/${string}`
  | `/main-contractors/${string}/onboarding`
  | `/main-contractors/${string}/payments/details`
  | `/main-contractors/${string}/payments`
  | `/main-contractors/${string}/payments/payment-caps`
  | `/main-contractors/${string}/rate-cards/configurator`
  | `/main-contractors/${string}/rate-cards`
  | `/main-contractors/${string}/users`
  | `/main-contractors/archived`
  | `/main-contractors/inactive`
  | `/main-contractors`
  | `/main-contractors/invite`
  | `/main-contractors/onboarding`
  | `/onboarding/about-wise`
  | `/onboarding/about-your-business`
  | `/onboarding/checking-details`
  | `/onboarding/contracts-and-policies`
  | `/onboarding/create-account`
  | `/onboarding/finished`
  | `/onboarding/protecting-your-business`
  | `/onboarding/set-up-steps`
  | `/onboarding/welcome`
  | `/payments/${string}/${string}`
  | `/payments/closed`
  | `/payments/completed`
  | `/payments/depot/${string}`
  | `/payments/in-progress`
  | `/payments`
  | `/payments/payrun/${string}`
  | `/payments/submitted`
  | `/reports/awaiting-activation`
  | `/reports/deductions-overview`
  | `/reports/driver-depots`
  | `/reports`
  | `/reports/signed-policies`
  | `/reports/slas`
  | `/reports/unresponsive-applications`
  | `/reports/utr-report`
  | `/resource/direct/available`
  | `/resource/direct/offboarded`
  | `/resource/direct/onboarding`
  | `/resource/partners/available`
  | `/resource/partners/disengaged`
  | `/resource/partners/onboarding`
  | `/scheduling/${string}/${string}`
  | `/scheduling/${string}/${string}/start-times`
  | `/scheduling`
  | `/scheduling/route-library/${string}/${string}`
  | `/settings/carrier-settings`
  | `/settings/document-library`
  | `/settings/driving-licence-checks`
  | `/settings`
  | `/settings/payment-caps`
  | `/settings/payments`
  | `/settings/rate-cards`
  | `/settings/users`
  | `/users`
  | `/worklog/${string}/${string}/history`
  | `/worklog/${string}/history`
  | `/worklog` {
  switch (name) {
    case '/404':
      return `/404`
    case '/auth/action':
      return `/auth/action`
    case '/auth/change-password':
      return `/auth/change-password`
    case '/auth/reset-password':
      return `/auth/reset-password`
    case '/auth/verify':
      return `/auth/verify`
    case '/compliance/driving-licence':
      return `/compliance/driving-licence`
    case '/compliance/evidence':
      return `/compliance/evidence`
    case '/customers/[id]':
      return `/customers/${arg0}`
    case '/customers':
      return `/customers`
    case '/debug/app-config':
      return `/debug/app-config`
    case '/debug/dev':
      return `/debug/dev`
    case '/debug/feature-flags':
      return `/debug/feature-flags`
    case '/debug/maintenance':
      return `/debug/maintenance`
    case '/drivers/[id]/engagement-details':
      return `/drivers/${arg0}/engagement-details`
    case '/drivers/[id]/engagements/[mcId]':
      return `/drivers/${arg0}/engagements/${arg1}`
    case '/drivers/[id]/financial-details':
      return `/drivers/${arg0}/financial-details`
    case '/drivers/[id]/incidents':
      return `/drivers/${arg0}/incidents`
    case '/drivers/[id]':
      return `/drivers/${arg0}`
    case '/drivers/[id]/payments/[summaryId]':
      return `/drivers/${arg0}/payments/${arg1}`
    case '/drivers/[id]/payments/create/[payrunId]':
      return `/drivers/${arg0}/payments/create/${arg1}`
    case '/drivers/[id]/vehicle':
      return `/drivers/${arg0}/vehicle`
    case '/drivers/active':
      return `/drivers/active`
    case '/drivers/inactive':
      return `/drivers/inactive`
    case '/drivers/onboarding':
      return `/drivers/onboarding`
    case '/evidence-template/edit/[id]':
      return `/evidence-template/edit/${arg0}`
    case '/evidence-template/labels':
      return `/evidence-template/labels`
    case '/evidence-template/new':
      return `/evidence-template/new`
    case '/evidence-template/templates':
      return `/evidence-template/templates`
    case '/forgot-password':
      return `/forgot-password`
    case '/':
      return `/`
    case '/knowledge-base/[...category]':
      return `/knowledge-base/${arg0}`
    case '/knowledge-base':
      return `/knowledge-base`
    case '/login':
      return `/login`
    case '/logout':
      return `/logout`
    case '/main-contractors/[mcId]/carrier-settings':
      return `/main-contractors/${arg0}/carrier-settings`
    case '/main-contractors/[mcId]/depots':
      return `/main-contractors/${arg0}/depots`
    case '/main-contractors/[mcId]/document-library/archived':
      return `/main-contractors/${arg0}/document-library/archived`
    case '/main-contractors/[mcId]/document-library':
      return `/main-contractors/${arg0}/document-library`
    case '/main-contractors/[mcId]/document-library/uploaded':
      return `/main-contractors/${arg0}/document-library/uploaded`
    case '/main-contractors/[mcId]/documents/[documentId]':
      return `/main-contractors/${arg0}/documents/${arg1}`
    case '/main-contractors/[mcId]/driving-licence-checks':
      return `/main-contractors/${arg0}/driving-licence-checks`
    case '/main-contractors/[mcId]/import-drivers':
      return `/main-contractors/${arg0}/import-drivers`
    case '/main-contractors/[mcId]':
      return `/main-contractors/${arg0}`
    case '/main-contractors/[mcId]/onboarding':
      return `/main-contractors/${arg0}/onboarding`
    case '/main-contractors/[mcId]/payments/details':
      return `/main-contractors/${arg0}/payments/details`
    case '/main-contractors/[mcId]/payments':
      return `/main-contractors/${arg0}/payments`
    case '/main-contractors/[mcId]/payments/payment-caps':
      return `/main-contractors/${arg0}/payments/payment-caps`
    case '/main-contractors/[mcId]/rate-cards/configurator':
      return `/main-contractors/${arg0}/rate-cards/configurator`
    case '/main-contractors/[mcId]/rate-cards':
      return `/main-contractors/${arg0}/rate-cards`
    case '/main-contractors/[mcId]/users':
      return `/main-contractors/${arg0}/users`
    case '/main-contractors/archived':
      return `/main-contractors/archived`
    case '/main-contractors/inactive':
      return `/main-contractors/inactive`
    case '/main-contractors':
      return `/main-contractors`
    case '/main-contractors/invite':
      return `/main-contractors/invite`
    case '/main-contractors/onboarding':
      return `/main-contractors/onboarding`
    case '/onboarding/about-wise':
      return `/onboarding/about-wise`
    case '/onboarding/about-your-business':
      return `/onboarding/about-your-business`
    case '/onboarding/checking-details':
      return `/onboarding/checking-details`
    case '/onboarding/contracts-and-policies':
      return `/onboarding/contracts-and-policies`
    case '/onboarding/create-account':
      return `/onboarding/create-account`
    case '/onboarding/finished':
      return `/onboarding/finished`
    case '/onboarding/protecting-your-business':
      return `/onboarding/protecting-your-business`
    case '/onboarding/set-up-steps':
      return `/onboarding/set-up-steps`
    case '/onboarding/welcome':
      return `/onboarding/welcome`
    case '/payments/[mcId]/[depotId]':
      return `/payments/${arg0}/${arg1}`
    case '/payments/closed':
      return `/payments/closed`
    case '/payments/completed':
      return `/payments/completed`
    case '/payments/depot/[depotId]':
      return `/payments/depot/${arg0}`
    case '/payments/in-progress':
      return `/payments/in-progress`
    case '/payments':
      return `/payments`
    case '/payments/payrun/[id]':
      return `/payments/payrun/${arg0}`
    case '/payments/submitted':
      return `/payments/submitted`
    case '/reports/awaiting-activation':
      return `/reports/awaiting-activation`
    case '/reports/deductions-overview':
      return `/reports/deductions-overview`
    case '/reports/driver-depots':
      return `/reports/driver-depots`
    case '/reports':
      return `/reports`
    case '/reports/signed-policies':
      return `/reports/signed-policies`
    case '/reports/slas':
      return `/reports/slas`
    case '/reports/unresponsive-applications':
      return `/reports/unresponsive-applications`
    case '/reports/utr-report':
      return `/reports/utr-report`
    case '/resource/direct/available':
      return `/resource/direct/available`
    case '/resource/direct/offboarded':
      return `/resource/direct/offboarded`
    case '/resource/direct/onboarding':
      return `/resource/direct/onboarding`
    case '/resource/partners/available':
      return `/resource/partners/available`
    case '/resource/partners/disengaged':
      return `/resource/partners/disengaged`
    case '/resource/partners/onboarding':
      return `/resource/partners/onboarding`
    case '/scheduling/[mcId]/[depotId]':
      return `/scheduling/${arg0}/${arg1}`
    case '/scheduling/[mcId]/[depotId]/start-times':
      return `/scheduling/${arg0}/${arg1}/start-times`
    case '/scheduling':
      return `/scheduling`
    case '/scheduling/route-library/[mcId]/[depotId]':
      return `/scheduling/route-library/${arg0}/${arg1}`
    case '/settings/carrier-settings':
      return `/settings/carrier-settings`
    case '/settings/document-library':
      return `/settings/document-library`
    case '/settings/driving-licence-checks':
      return `/settings/driving-licence-checks`
    case '/settings':
      return `/settings`
    case '/settings/payment-caps':
      return `/settings/payment-caps`
    case '/settings/payments':
      return `/settings/payments`
    case '/settings/rate-cards':
      return `/settings/rate-cards`
    case '/settings/users':
      return `/settings/users`
    case '/users':
      return `/users`
    case '/worklog/[id]/[locationId]/history':
      return `/worklog/${arg0}/${arg1}/history`
    case '/worklog/[id]/history':
      return `/worklog/${arg0}/history`
    case '/worklog':
      return `/worklog`
  }
}
