import { now, parseDate } from '@wise/utils'
import * as React from 'react'

import { APP_VERSION } from '~shared/config/version'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { isServerSide } from '~shared/services/context'
import { trpcProxy } from '~shared/services/trpc'
import { createLogger } from '~shared/utils/log'

type VersionCheckCallback = () => void

const VERSION_CHECK_KEY = 'mcp.version.lastUpdateCheck'

const log = createLogger('useVersionCheck')

export const useVersionCheck = (): VersionCheckCallback => {
  const performCheck = React.useCallback(async () => {
    log('useVersionCheck', 'Beginning check...')
    try {
      const response = await trpcProxy.meta.version.query()

      const { version } = response
      log('useVersionCheck', 'Version result =', version)
      if (version !== APP_VERSION) {
        window && window.location.reload()
      }
      sessionStorage.setItem(VERSION_CHECK_KEY, new Date().toISOString())
    } catch (error) {
      log('useVersionCheck', 'Error', error)
      leaveBreadcrumb('Upgrade check request failed!', { error }, 'error')
      notify(error)
    }
  }, [])

  const attemptCheck = React.useCallback(() => {
    // We don't ever need to version check server-side
    if (isServerSide()) return

    const lastCheck = sessionStorage.getItem(VERSION_CHECK_KEY)

    if (
      !lastCheck ||
      parseDate(lastCheck)?.isBefore(now().subtract(30, 'minutes'))
    ) {
      performCheck()
    }
  }, [performCheck])

  return attemptCheck
}
