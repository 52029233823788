import cx from 'classnames'
import { motion } from 'framer-motion'

import useTheme from '~shared/hooks/useTheme'

import ClickInterceptor from '../ClickInterceptor/ClickInterceptor'

import CloseModalButton from './CloseModalButton'
import { Modal as ModalType, removeModal } from './useModal'

interface Props {
  first: boolean
  modal: ModalType
}

const Modal = ({ first, modal }: Props): JSX.Element => {
  const theme = useTheme()

  const { component: Component, ...modalProps } = modal

  const getBgColor = () => {
    if (theme === 'purple' && first) return 'bg-purple-900/95'
    if (theme === 'purple' && !first) return 'bg-purple-900/50'
    if (theme === 'green' && first) return 'bg-green-900/95'
    if (theme === 'green' && !first) return 'bg-green-900/50'
  }

  return (
    <motion.div
      data-testid={modal.id}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.15 }}
      style={{ zIndex: 99999 }}
      className={cx(
        `absolute left-0 top-0 right-0 bottom-0 h-full overflow-y-auto overflow-x-hidden`,
        getBgColor(),
      )}
    >
      <ClickInterceptor
        disabled={!(modal.canBeClosed ?? false)}
        onIntercept={() => removeModal(modal.id)}
        className='flex min-h-full w-full flex-col items-center justify-center p-4'
      >
        <motion.div
          className='relative max-h-screen'
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.2, delay: 0.15 }}
        >
          {modalProps.canBeClosed !== false ? (
            <CloseModalButton
              className={cx(
                'absolute top-3 right-3 z-10 text-xl',
                modalProps.closeButtonClassName,
              )}
              onClick={() => removeModal(modal.id)}
            />
          ) : null}
          {typeof Component === 'function' ? (
            <Component {...modalProps} />
          ) : (
            Component
          )}
          <div className='h-6'></div>
        </motion.div>
      </ClickInterceptor>
    </motion.div>
  )
}
export default Modal
