import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  HttpOptions,
  from,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { isNonEmptyString, memo, randomString } from '@wise/utils'
import { castArray, get } from 'lodash'

import { APP_ENV, AppEnv } from '~shared/config/app'
import { publicRuntimeConfig } from '~shared/config/runtime'
import { APP_VERSION } from '~shared/config/version'
import { isClientSide, isServerSide } from '~shared/services/context'
import { createLogger } from '~shared/utils/log'

import { addMetadata, leaveBreadcrumb, notify } from '../bugsnag/client'
import { Authenticator } from '../firebase/auth/Authenticator'

import { ApolloCacheType, createApolloCache } from './cache'

export type ApolloClientType = ApolloClient<ApolloCacheType>

const log = createLogger('apolloClient')

export const getAuthHeader = async (): Promise<HttpOptions['headers']> => {
  const sessionToken = await Authenticator.getSessionToken()
  return sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}
}

export const TRACE_HEADER = 'X-Wise-Trace-Id'

const apolloErrorLink = onError(
  ({ networkError, graphQLErrors, response, operation }) => {
    const ctx = operation.getContext()
    const traceId = get(ctx, ['headers', TRACE_HEADER])
    if (isNonEmptyString(traceId)) addMetadata('Request', { traceId })
    log('apolloErrorLink invoked', {
      networkError,
      graphQLErrors,
      response,
      operation,
    })
    if (operation) {
      leaveBreadcrumb(
        'API Request Error',
        {
          name: operation.operationName,
          query: operation.query.loc?.source.body || 'Not defined',
          extensions: operation.extensions,
          variables: operation.variables,
        },
        'error',
      )
    }
    if (response) {
      leaveBreadcrumb('API Response Error', { response }, 'error')
    }
    if (graphQLErrors) {
      leaveBreadcrumb('GraphQL Errors', { errors: graphQLErrors }, 'error')
      for (const err of graphQLErrors) {
        if (err.extensions?.code === 'UNAUTHENTICATED') {
          leaveBreadcrumb(
            'Unauthenticated user - attempting to refresh token...',
          )
          Authenticator.refreshToken()
        }
        notify(err)
      }
    }
    if (networkError) {
      notify(networkError)
    }
  },
)

const firebaseTokenLink = setContext(async (_, previousContext) => ({
  ...previousContext,
  headers: {
    ...previousContext?.headers,
    ...(await getAuthHeader()),
  },
}))

const traceIdLink = setContext(async (_, previousContext) => ({
  ...previousContext,
  headers: {
    ...previousContext.headers,
    [TRACE_HEADER]: randomString(),
  },
}))

const breadcrumbLink = new ApolloLink((operation, forward) => {
  log('GraphQL Request - ' + (operation.operationName || 'Unknown Operation'))
  leaveBreadcrumb(
    'GraphQL Request - ' + (operation.operationName || 'Unknown Operation'),
    undefined,
    'request',
  )

  return forward(operation).map((response) => {
    leaveBreadcrumb(
      'GraphQL Response - ' + (operation.operationName || 'Unknown Operation'),
      { response },
      'request',
    )

    return response
  })
})

const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      const errorMessage = String(get(error, 'message', '')).toLowerCase()
      if (
        errorMessage.includes('aborted') ||
        errorMessage.includes('aborterror')
      ) {
        // Don't retry aborted requests
        return false
      }

      // Is it an error with GraphQL validation?
      const isGraphQlError = castArray(get(error, 'result.errors', [])).some(
        (i) => get(i, 'extensions.code') === 'GRAPHQL_VALIDATION_FAILED',
      )

      if (isGraphQlError) {
        leaveBreadcrumb('retyLink', { isGraphQlError: true, error })
        return false
      }

      leaveBreadcrumb('retryLink', { error, operation }, 'error')
      return Boolean(error)
    },
  },
  delay: {
    initial: 300,
    jitter: true,
  },
})

export const getMaincontractorGraphqlEndpoint = (): string | undefined =>
  publicRuntimeConfig.graphqlEndpoint

export const getApolloLink = (): ApolloLink => {
  const uri = getMaincontractorGraphqlEndpoint()
  const httpLink = new HttpLink({ uri })

  return from([
    apolloErrorLink,
    breadcrumbLink,
    traceIdLink,
    retryLink,
    firebaseTokenLink,
    httpLink,
  ])
}

/**
 * Controls what environments are allowed to connect to the Apollo DevTools
 * (if installed in your browser)
 * true = allowed, false = disallowed
 */
const APOLLO_DEVTOOLS: Record<AppEnv, boolean> = {
  demo: false,
  dev: true,
  local: true,
  production: false,
  qa: true,
  staging: true,
  test: true,
  unknown: false,
}

export const getIsolatedApolloClient = (): ApolloClientType => {
  return new ApolloClient({
    name: `wise-mcp-${APP_ENV}`,
    version: APP_VERSION,
    ssrMode: isServerSide(),
    cache: createApolloCache(),
    link: getApolloLink(),
    connectToDevTools: isClientSide() && APOLLO_DEVTOOLS[APP_ENV],
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  })
}

export const getApolloClient = memo<ApolloClientType>(() => {
  return getIsolatedApolloClient()
})

export const initializeApolloClient = (): ApolloClientType => {
  log('initializeApolloClient', isServerSide() ? 'server' : 'client')
  const client = getApolloClient()
  const link = getApolloLink()
  client.setLink(link)
  return client
}

export const rootApolloClient = initializeApolloClient()

/**
 * Sets up an Apollo Client connection from either Client or Server side.
 * This should only be done once in the application, as close to the app
 * root as possible.
 * @param uri The GQL endpoint to hit
 * @param initialState Any initial GQL state
 */
export const useApollo = () => rootApolloClient
