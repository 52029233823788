import { useMode } from '~shared/hooks/useMode'

export type Theme = 'purple' | 'green'

const useTheme = (): Theme => {
  const mode = useMode()

  switch (mode) {
    case 'WAP':
      return 'green'
    case 'MCP':
    case 'NAP':
    default:
      return 'purple'
  }
}

export default useTheme
