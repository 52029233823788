import { kebabCase } from 'lodash'
import * as React from 'react'
import { FieldError } from 'react-hook-form'

import Label from '../../Form/Label'
import FormError from '../../FormError/FormError'

interface Props {
  error?: FieldError
  label?: string
  testId?: string
}

const Checkbox = React.forwardRef<
  HTMLInputElement,
  Props & JSX.IntrinsicElements['input']
>(({ error, label, className, testId: rawTestId, ...props }, ref) => {
  const { testId, errorTestId } = React.useMemo(() => {
    const raw = rawTestId || props.id || undefined
    if (!raw) return { testId: undefined, errorTestId: undefined }
    return { testId: kebabCase(raw), errorTestId: `${kebabCase(raw)}-error` }
  }, [props.id, rawTestId])

  return (
    <div className={className}>
      <div className='flex'>
        <input
          {...props}
          aria-label={label}
          data-testid={testId}
          type='checkbox'
          ref={ref}
          className='mr-3'
        />
        <Label htmlFor={props.id} hasError={Boolean(error)}>
          {label}
        </Label>
      </div>
      <FormError testId={errorTestId}>{error?.message}</FormError>
    </div>
  )
})

Checkbox.displayName = 'Checkbox'

export default React.memo(Checkbox)
