import React from 'react'

import Loading from '~shared/components/Loading/Loading'
import useTheme from '~shared/hooks/useTheme'

import { ModalProps } from '../../useModal'

interface Props {
  children: React.ReactChild | undefined
}

const LoadingModal = ({ children }: Props & ModalProps) => {
  const theme = useTheme()
  return (
    <div className='flex max-w-500 flex-col items-center justify-center gap-3 space-y-3 rounded-xl bg-white p-12 shadow-md'>
      <Loading colour={theme} />
      {children ?? null}
    </div>
  )
}

export default LoadingModal
