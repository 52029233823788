import { notify } from '~shared/services/bugsnag/client'
import { trpcProxy } from '~shared/services/trpc'

import { AsyncDownloads } from './async-download'
import { asyncDownloadSchema } from './async-download/validator'
import { WorklogEvents } from './worklog'
import { worklogEventSchema } from './worklog/validator'

export class NewToast {
  public static handler(
    log: (...args: unknown[]) => void,
    type: unknown,
    data: unknown,
  ) {
    try {
      // Try and treat as an AsyncDownload
      const asyncDownloadResult = asyncDownloadSchema.safeParse({
        type,
        data,
      })
      if (asyncDownloadResult.success) {
        return AsyncDownloads.handler(log, asyncDownloadResult.data)
      }

      // Try and treat as a Worklog event
      const worklogEventResult = worklogEventSchema.safeParse({
        type,
        data,
      })
      if (worklogEventResult.success) {
        return WorklogEvents.handler(log, worklogEventResult.data)
      }

      // Otherwise - throw an error!
      throw new Error('Unknown "new-toast" event type!')
    } catch (error) {
      notify({
        name: 'Failed to handle "new-toast" event',
        error,
        data: { type, data },
      })
      trpcProxy.services.mixpanel.trackEvent.mutate({
        name: 'NewToastError',
        properties: {
          cause: error,
          payload: { type, data },
          reason: 'InvalidResponse',
        },
      })
    }
  }
}
