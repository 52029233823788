import { kebabCase } from 'lodash'
import { twMerge } from 'tailwind-merge'

interface Props {
  hasError?: boolean
  testId?: string
}

const Label: React.FC<Props & JSX.IntrinsicElements['label']> = ({
  children,
  className,
  hasError = false,
  testId,
  ...props
}) => {
  if (!children) return null

  return (
    <label
      {...props}
      data-testid={testId ? `label-${kebabCase(testId)}` : undefined}
      className={twMerge(
        'text-base font-bold',
        hasError && 'text-red',
        className ? className : 'ml-1 ',
      )}
    >
      {children}
    </label>
  )
}

export default Label
