import { z } from 'zod'

export const worklogEventSchema = z.object({
  type: z.literal('AMAZON_FLEX_SCRAPE_COMPLETED'),
  data: z.object({
    isSuccess: z.preprocess(
      // The websocket returns "true" as a string
      (val) => val === 'true' || val === true,
      z.boolean(),
    ),
    scrapeId: z.string(),
    type: z.union([
      z.literal('manual_login'),
      z.literal('manual_worklog_refresh'),
    ]),
  }),
})

export type WorklogEventOutput = z.infer<typeof worklogEventSchema>
