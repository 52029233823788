import cx from 'classnames'
import { motion } from 'framer-motion'
import { kebabCase } from 'lodash'
import * as React from 'react'

import Loading from '../../Loading/Loading'

interface Props {
  loading?: boolean
  containerClassName?: string
  testId?: string
}

const Button = React.forwardRef<
  HTMLButtonElement,
  Props & JSX.IntrinsicElements['button']
>(
  (
    {
      className,
      children,
      disabled,
      loading,
      type = 'button',
      containerClassName,
      testId,
      ...props
    },
    ref,
  ) => {
    return (
      <motion.div
        className={cx(
          'flex w-auto items-center justify-center',
          containerClassName,
        )}
        initial={{ scale: 1 }}
        animate={{ scale: loading ? 0.9 : 1, opacity: loading ? 0.75 : 1 }}
      >
        <button
          {...props}
          data-testid={testId ? kebabCase(testId) : undefined}
          type={type}
          ref={ref}
          className={cx(className, 'relative')}
          disabled={disabled || loading}
        >
          <motion.div
            initial={{ opacity: 1, scale: 1 }}
            animate={{ opacity: loading ? 0.15 : 1, scale: loading ? 0.8 : 1 }}
            className='flex flex-row items-center justify-center text-center tracking-wider'
          >
            {children}
          </motion.div>
          {loading ? (
            <motion.div
              className='absolute inset-0 flex items-center justify-center'
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: loading ? 1 : 0, scale: loading ? 1 : 0.3 }}
            >
              <Loading />
            </motion.div>
          ) : null}
        </button>
      </motion.div>
    )
  },
)

Button.displayName = 'Button'

export default React.memo(Button)
