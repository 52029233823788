import cx from 'classnames'
import { AnimatePresence } from 'framer-motion'
import * as React from 'react'
import { useDebounce } from 'use-debounce'

import Modal from './Modal'
import { popModal, useModalState } from './useModal'

const ModalWrapper: React.FC = ({ children }) => {
  const { stack: rawStack } = useModalState()

  const [stack] = useDebounce(rawStack, 50)

  const modalOpen = stack.length > 0

  const handlePopModal = React.useCallback(() => {
    if (stack.length === 0) return
    const topModal = stack[stack.length - 1]
    if (topModal.canBeClosed === false) return
    popModal()
  }, [stack])

  React.useEffect(() => {
    const listener = (ke: KeyboardEvent): void => {
      if (ke.key === 'Escape') {
        ke.stopPropagation()
        handlePopModal()
      }
    }
    window.addEventListener('keydown', listener)
    return () => window.removeEventListener('keydown', listener)
  }, [handlePopModal])

  return (
    <>
      <div
        className={cx(
          'h-full w-full',
          modalOpen && 'relative z-[99999] blur-sm',
        )}
      >
        {children}
      </div>
      <AnimatePresence>
        {stack.map((modal, ix) => (
          <Modal key={modal.id} modal={modal} first={ix === 0} />
        ))}
      </AnimatePresence>
    </>
  )
}

export default ModalWrapper
