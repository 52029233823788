import { MaintenanceConfig } from '@wise/kv/extensions/maintenance-mode/validation'
import { djs } from '@wise/utils'
import * as React from 'react'

import InstagramIcon from '~shared/components/Icons/svg/instagram.svg'
import LinkedinIcon from '~shared/components/Icons/svg/linkedin.svg'
import TwitterIcon from '~shared/components/Icons/svg/twitter.svg'
import WiseLogo from '~shared/components/Icons/svg/wise-logo.svg'
import YoutubeIcon from '~shared/components/Icons/svg/youtube.svg'

export interface MaintenanceModeProps {
  config: MaintenanceConfig['maintenanceMode']
}

const MaintenanceMode = ({ config }: MaintenanceModeProps) => {
  const parallaxRef = React.useRef<HTMLDivElement>(null)

  const to = React.useMemo(
    () => (config.to ? djs(config.to) : null),
    [config.to],
  )

  const year = React.useMemo(() => new Date().getFullYear(), [])

  React.useEffect(() => {
    const boxMouseOverHandler = (e: MouseEvent) => {
      const parallax: HTMLDivElement | null = parallaxRef.current
      if (!parallax) return

      const _w = window.innerWidth / 2
      const _h = window.innerHeight / 2
      const _mouseX = e.clientX
      const _mouseY = e.clientY
      const _depth1 = `${50 - (_mouseX - _w) * 0.001}% ${
        50 - (_mouseY - _h) * 0.001
      }%`
      const _depth2 = `${50 - (_mouseX - _w) * 0.001}% ${
        50 - (_mouseY - _h) * 0.001
      }%`
      const _depth3 = `${50 - (_mouseX - _w) * 0.001}% ${
        50 - (_mouseY - _h) * 0.001
      }%`
      const x = `${_depth3}, ${_depth2}, ${_depth1}`
      parallax.style.backgroundPosition = x
    }

    document.addEventListener('mousemove', boxMouseOverHandler)

    return () => {
      document.removeEventListener('mousemove', boxMouseOverHandler)
    }
  }, [])

  return (
    <div className='maintenance-page'>
      <div className='maintenance-page__header'>
        <div ref={parallaxRef} className='maintenance-page__parallax'></div>
        <img
          className='maintenance-page__img mt-auto w-full'
          src='/images/maintenance-bg.svg'
          alt='Clouds and Rocket Graphic'
        />
      </div>
      <div className='maintenance-page__band pb-6'>
        <div className='maintenance-page__content'>
          <h2 className='maintenance-page__title mb-6 text-4xl font-bold tracking-tight text-black 2xl:text-5xl'>
            Scheduled maintenance.
          </h2>
          <p className='maintenance-page__text mb-6 text-lg'>
            We&apos;re working on improving the Wise experience.
            <br />
            {to
              ? `We're scheduled to be live again at:`
              : `We'll be back as soon as possible!`}
          </p>
          {to ? (
            <div className='maintenance-page__date mb-6'>
              <p className='text-xl font-bold text-purple'>
                {to.format('DD MMMM YYYY [at] h:mmA')}
              </p>
            </div>
          ) : null}
          <ul className='maintenance-page__social mb-6 text-sm font-light text-black/40'>
            <li className='mx-1.5 my-2 rounded-full bg-grey-darker transition-all ease-linear hover:scale-125 hover:bg-purple'>
              <a
                href='https://www.youtube.com/channel/UCzEUekzpYZdetKdfBEEFnmA/featured'
                target='_blank'
                rel='noopener noreferrer'
                className='block p-1'
              >
                <YoutubeIcon className='text-md text-white' />
              </a>
            </li>
            <li className='mx-1.5 my-2 rounded-full bg-grey-darker transition-all ease-linear hover:scale-125 hover:bg-purple'>
              <a
                href='https://www.instagram.com/wisesocial_/'
                target='_blank'
                rel='noopener noreferrer'
                className='block p-1'
              >
                <InstagramIcon className='text-md text-white' />
              </a>
            </li>
            <li className='mx-1.5 my-2 rounded-full bg-grey-darker transition-all ease-linear hover:scale-125 hover:bg-purple'>
              <a
                href='https://twitter.com/WiseSocial_'
                target='_blank'
                rel='noopener noreferrer'
                className='block p-1'
              >
                <TwitterIcon className='text-md text-white' />
              </a>
            </li>
            <li className='mx-1.5 my-2 rounded-full bg-grey-darker transition-all ease-linear hover:scale-125 hover:bg-purple'>
              <a
                href='https://www.linkedin.com/company/withwise'
                target='_blank'
                rel='noopener noreferrer'
                className='block p-1'
              >
                <LinkedinIcon className='text-md text-white' />
              </a>
            </li>
          </ul>
          <footer className='flex flex-row items-center justify-center text-center text-base font-light text-black/40'>
            &copy; {year} <WiseLogo className='mx-1 inline-block w-8' /> All
            Rights Reserved.
          </footer>
        </div>
      </div>
    </div>
  )
}

export default MaintenanceMode
