import { DefaultOptions, MiddlewareHandler } from '../types'
import Modulr from '../wrappers/Modulr'

type Options = Omit<DefaultOptions, 'wrapper'>

export const modulr = (options?: Options): MiddlewareHandler => ({
  fn: () => ({ status: 'ok' }),
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: Modulr,
})
