import Router, { NextRouter, useRouter } from 'next/router'
import * as React from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { getLatestQueryParams, useSearchStore } from './pathStore'
import { parseQueryParams, stringifyQueryParams } from './qs'

interface QueryParamsContextType {
  get<T>(key: string): T | undefined
  set<T>(key: string, value: T | undefined): void
  setMany(values: Record<string, unknown>): void

  queryParams: Record<string, unknown>
  search: string | undefined
}

export const DEFAULT_QUERY_PARAMS_CONTEXT: QueryParamsContextType = {
  queryParams: {},
  search: undefined,
  get: () => void 0,
  set: () => void 0,
  setMany: () => void 0,
}

export const QueryParamsContext = React.createContext<QueryParamsContextType>(
  DEFAULT_QUERY_PARAMS_CONTEXT,
)

interface Props {
  children: React.ReactNode
  path?: string
  setUrl?: (path: string) => void
}

export const QueryParamsProvider = ({
  children,
  path: providedPath,
  setUrl,
}: Props) => {
  const router = useRouter() as NextRouter | null
  const setUrlRef = React.useRef(setUrl)

  React.useEffect(() => {
    if (!router) return
    const listener = (_: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) useSearchStore.getState().updateSearch(null)
    }
    router.events.on('routeChangeStart', listener)
    return () => router.events.off('routeChangeStart', listener)
  })

  /**
   * This function has no dependencies as we will only generate this function once and it gets re-used in the effects
   * Otherwise we can get stuck in infinite loops when setting the routing state...
   */
  const updateUrl = React.useCallback(() => {
    const fullPathname = (providedPath || Router.asPath || '').split('?')[0]
    const query = useSearchStore.getState().search
    if (query === null) return
    const pathname =
      fullPathname.length > 1 ? fullPathname.replace(/\/$/, '') : fullPathname
    if (setUrlRef.current) {
      return setUrlRef.current(pathname + (query ? `?${query}` : ''))
    }
    if (Router) {
      return Router.replace({ pathname, query }, undefined, {
        shallow: true,
      }).then(() => useSearchStore.getState().updateSearch(null))
    }
    throw new Error('Cannot replace URL!')
  }, [providedPath])

  const replace = useDebouncedCallback(updateUrl, 50)

  const searchFromRouter = React.useMemo<QueryParamsContextType['search']>(
    () => (providedPath || router?.asPath)?.split('?')[1] || undefined,
    [providedPath, router?.asPath],
  )
  const searchFromUnflushedChanges = useSearchStore((s) => s.search)

  const search = React.useMemo<QueryParamsContextType['search']>(
    () =>
      searchFromUnflushedChanges !== null
        ? searchFromUnflushedChanges
        : searchFromRouter,
    [searchFromRouter, searchFromUnflushedChanges],
  )

  const queryParams = React.useMemo<QueryParamsContextType['queryParams']>(
    () => (search ? parseQueryParams(search) : {}),
    [search],
  )

  const get = React.useCallback<QueryParamsContextType['get']>(
    <T,>(key: string): T | undefined => {
      const val = getLatestQueryParams(providedPath)[key]
      return (val ?? undefined) as T | undefined
    },
    [providedPath],
  )

  const set = React.useCallback<QueryParamsContextType['set']>(
    <T,>(key: string, value: T | undefined): void => {
      const newQueryParams = { ...getLatestQueryParams(), [key]: value }
      if (value === undefined) delete newQueryParams[key]
      useSearchStore
        .getState()
        .updateSearch(stringifyQueryParams(newQueryParams))
      replace()
    },
    [replace],
  )

  const setMany = React.useCallback<QueryParamsContextType['setMany']>(
    (values: Record<string, unknown>): void => {
      const newQueryParams = { ...getLatestQueryParams(), ...values }
      Object.entries(values).forEach(([key, value]) => {
        if (value === undefined) delete newQueryParams[key]
      })
      useSearchStore
        .getState()
        .updateSearch(stringifyQueryParams(newQueryParams))
      replace()
    },
    [replace],
  )

  const value = React.useMemo<QueryParamsContextType>(
    () => ({ get, queryParams, search, set, setMany }),
    [get, queryParams, search, set, setMany],
  )

  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  )
}
