import {
  Permission,
  PermissionQuery,
  PermissionQueryObject,
  RawPermission,
} from './types'

export type PermissionCheckType = 'OR' | 'AND'

const WILDCARD_CHARACTER = '*'

const matchIdentifier = (
  identifier: string | undefined,
  query: string | undefined,
  optionalQuery = false,
): boolean => {
  /**
   * If there is no provided identifier, or if the query is optional
   * and we have not provided anything, then we assume we were not querying
   * on this identifier and as such should always return a match.
   */
  if (identifier === undefined || (optionalQuery && !query)) return true
  /**
   * If the query or identifier are a wildcard character, then we always return
   * true, otherwise the query must directly match the identifier.
   */
  return (
    query === WILDCARD_CHARACTER ||
    identifier === WILDCARD_CHARACTER ||
    query === identifier
  )
}

const permissionCheck = (
  permission: Permission,
  query: PermissionQuery,
): boolean => {
  const queryObject = parsePermissionQuery(query)

  const namespace = matchIdentifier(queryObject.namespace, permission[0])
  const resource = matchIdentifier(queryObject.resource, permission[1])
  const action = matchIdentifier(queryObject.action, permission[2], true)

  return namespace && resource && action
}

const validPermissionQuery = (query: PermissionQuery): boolean => {
  const pqo = parsePermissionQuery(query)
  return Boolean(pqo.namespace || pqo.resource || pqo.action)
}

/**
 * @param permissions A list of the permissions granted on the user
 * @param query The permission query you wish to check for
 */
export const hasPermission = (
  permissions: Permission[],
  query: PermissionQuery | PermissionQuery[],
  mode: PermissionCheckType = 'AND',
): boolean => {
  if (Array.isArray(query)) {
    switch (mode) {
      case 'AND':
        return query.every((q) => hasPermission(permissions, q))
      case 'OR':
        return query.some((q) => hasPermission(permissions, q))
    }
  }

  if (!validPermissionQuery(query))
    throw new Error(
      'No permission query values were specified, this is not valid.',
    )

  return permissions.some((permission) => permissionCheck(permission, query))
}

export const parsePermission = (
  permission: string | RawPermission,
): Permission => {
  if (!isRawPermission(permission)) {
    throw new Error(
      `The raw permission provided is not in the valid format => "${permission}"`,
    )
  }

  const [namespace, resource, action] = permission.split('.') as Permission

  if (namespace === WILDCARD_CHARACTER) {
    throw new Error('The namespace cannot be wildcarded')
  }

  if (
    action &&
    action !== WILDCARD_CHARACTER &&
    resource === WILDCARD_CHARACTER
  ) {
    throw new Error('Wildcards must be at the end of the query')
  }

  if (namespace && resource && action && action !== '*')
    return [namespace, resource, action]
  return [namespace, resource]
}

const parsePermissionQuery = (
  query: PermissionQuery,
): PermissionQueryObject => {
  if (isPermissionQueryObject(query)) return query
  const res = query.split('.')
  const pqo: PermissionQueryObject = {}

  ;(['namespace', 'resource', 'action'] as const).forEach((part, ix) => {
    const value = res[ix]
    if (value && value !== WILDCARD_CHARACTER) pqo[part] = value
  })

  return pqo
}

export const isRawPermission = (
  permissionString: string | RawPermission,
): permissionString is RawPermission => {
  const res = permissionString.split('.')
  // Length check, permissions should be 2 or 3 identifiers long
  if (res.length < 2 || res.length > 3) return false
  return true
}

const isPermissionQueryObject = (
  query: PermissionQuery,
): query is PermissionQueryObject => {
  return typeof query === 'object'
}
