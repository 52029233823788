import {
  PermissionCheckType,
  hasPermission,
} from '~shared/permissions/permissions'
import { PermissionQuery } from '~shared/permissions/types'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions & {
  permission: PermissionQuery[]
  permissionMode?: PermissionCheckType
}

export const permission = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    return hasPermission(
      ctx.permissions,
      options?.permission ?? [],
      options?.permissionMode,
    )
      ? { status: 'ok' }
      : { status: 'redirect', url: options?.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
