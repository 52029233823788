import * as React from 'react'

import { useUser } from '~shared/services/firebase/auth/hooks'

import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import useNotificationManager, {
  UseNotificationManager,
} from './useNotificationManager'

export const NotificationsContext = React.createContext<UseNotificationManager>(
  {
    enabled: false,
    loading: false,
    error: new Error('NotificationsContext not initialized'),
    notifications: [],
    unread: 0,
    status: 'disconnected',
    markAsRead: () => null,
  },
)

interface Props {
  children: React.ReactNode
}

const NotificationsProvider = ({ children }: Props) => {
  const mainContractor = useMaincontractor()
  const user = useUser()
  const notifications = useNotificationManager({
    mainContractorId: mainContractor?.id,
    userId: user?.id || '',
  })

  return (
    <NotificationsContext.Provider value={notifications}>
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
