import { djs } from '@wise/utils'
import { useRouter } from 'next/router'
import * as React from 'react'

import { type MaintenanceModeProps } from '~shared/components/MaintenanceMode/MaintenanceMode'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { isServerSide } from '~shared/services/context'
import { trpc } from '~shared/services/trpc'
import { log } from '~shared/utils/log'
import { Time } from '~shared/utils/Time'

import { useTimeout } from './useTimeout'

type PendingMaintenanceResult = {
  status: 'loading' | 'error'
  config?: never
  overriden?: never
}
type FinalMaintenanceResult = {
  status: 'in-maintenance' | 'live'
  config: MaintenanceModeProps['config']
  overriden?: boolean
}

export type UseMaintenanceModeResult =
  | PendingMaintenanceResult
  | FinalMaintenanceResult

const hasMaintenanceModeOverride = () => {
  try {
    if (isServerSide()) return false
    const value = window.localStorage.getItem('mcp_maintenance_override')
    return value === 'override'
  } catch (error) {
    leaveBreadcrumb('Maintenance mode override check failed')
    notify(error)
    return false
  }
}

export type MaintenanceModeStatus =
  | MaintenanceModeProps['config']
  | 'loading'
  | 'error'

const useMaintenanceMode = (): UseMaintenanceModeResult => {
  const config = trpc.config.maintenanceConfig.useQuery()
  const [state, setState] = React.useState<MaintenanceModeStatus>('loading')
  const [configCheckTimeoutOk, setConfigCheckTimeout] = useTimeout({
    key: `trigger-maintenance-config-check`,
  })

  const triggerConfigCheck = React.useCallback(() => {
    log('useMaintenanceMode', 'Checking for config update...')
    try {
      if (!config.data?.maintenanceMode) return

      leaveBreadcrumb('useMaintenanceMode', {
        config: config.data,
      })

      setState(config.data.maintenanceMode)

      setConfigCheckTimeout(Time.seconds(15))
    } catch (error) {
      leaveBreadcrumb('useMaintenanceMode', { error })
      notify(error)
      setState('error')
    }
  }, [config.data, setConfigCheckTimeout])

  const router = useRouter()

  React.useEffect(() => {
    if (state === 'loading') return triggerConfigCheck()

    if (isServerSide() || !configCheckTimeoutOk) return

    const fn = () => triggerConfigCheck()

    window.addEventListener('focus', fn)

    return () => window.removeEventListener('focus', fn)
  }, [
    configCheckTimeoutOk,
    triggerConfigCheck,
    config.data?.maintenanceMode,
    state,
  ])

  React.useEffect(() => {
    if (!configCheckTimeoutOk) return

    const fn = (_: string, options?: { shallow: boolean }) => {
      const skip = options?.shallow === true
      if (skip) return
      triggerConfigCheck()
    }

    router.events.on('routeChangeStart', fn)

    return () => router.events.off('routeChangeStart', fn)
  }, [configCheckTimeoutOk, router.events, triggerConfigCheck])

  const maintenanceModeOverride = hasMaintenanceModeOverride()

  if (state === 'error') return { status: 'error' }
  if (state === 'loading') return { status: 'loading' }
  // return ['in-maintenance-mode', state]
  if (maintenanceModeOverride)
    return { status: 'live', config: state, overriden: true }
  if (state.active) return { status: 'in-maintenance', config: state }

  const to = state.to ? djs(state.to) : undefined
  const from = state.from ? djs(state.from) : undefined

  if (from && !to && from.isValid() && djs().isAfter(from)) {
    return { status: 'in-maintenance', config: state }
  }

  if (to && !from && to.isValid() && djs().isBefore(to)) {
    return { status: 'in-maintenance', config: state }
  }

  if (
    from &&
    to &&
    from.isValid() &&
    to.isValid() &&
    djs().isBetween(from, to)
  ) {
    return { status: 'in-maintenance', config: state }
  }

  return { status: 'live', config: state }
}

export const MaintenanceModeContext = React.createContext<
  ReturnType<typeof useMaintenanceMode>
>({ status: 'error' })

export const useMaintenanceContext = () =>
  React.useContext(MaintenanceModeContext)

export default useMaintenanceMode
