import cx from 'classnames'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Button from '~shared/components/Buttons/Button/Button'
import { ModalProps, removeModal } from '~shared/components/Modal/useModal'
import ModalHeader from '~shared/components/UI/ModalHeader'
import useTheme from '~shared/hooks/useTheme'

interface Props {
  variant?: 'default' | 'success' | 'warning'
  title?: React.ReactChild
  description?: React.ReactChild
  onConfirm?: () => void
  confirmButtonContent?: React.ReactChild
  cancelButtonContent?: React.ReactChild
  autoRemove?: boolean
  showCancelButton?: boolean
}

const ConfirmModal = ({
  id: modalId,
  variant = 'default',
  title,
  confirmButtonContent,
  cancelButtonContent,
  description,
  onConfirm,
  autoRemove = true,
  showCancelButton = true,
}: Props & ModalProps): JSX.Element => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <div className='min-w-400 max-w-500 rounded-xl bg-white p-6 shadow-md'>
      <ModalHeader className='pr-12'>
        {title ?? t('confirm_modal.heading')}
      </ModalHeader>
      {description ? (
        typeof description === 'string' ? (
          <p className='whitespace-pre-wrap font-light'>{description}</p>
        ) : (
          <div className='whitespace-pre-wrap font-light'>{description}</div>
        )
      ) : null}
      <div className='mt-6 flex flex-row items-center justify-end gap-3'>
        {showCancelButton ? (
          <Button
            testId='cancel-modal-button'
            autoFocus
            type='button'
            className='rounded-full py-3 px-8 text-sm underline'
            onClick={() => removeModal(modalId)}
          >
            {cancelButtonContent || t('generic.cancel')}
          </Button>
        ) : null}
        <Button
          testId={`modal-confirm-button`}
          type='button'
          className={cx(
            'rounded-full px-8 py-3 text-sm font-bold text-white shadow-md',
            variant === 'success'
              ? 'bg-green'
              : variant === 'warning'
              ? 'bg-red'
              : theme === 'purple'
              ? 'bg-purple'
              : 'bg-green',
          )}
          onClick={() => {
            if (autoRemove) removeModal(modalId)
            onConfirm?.()
          }}
        >
          {confirmButtonContent || t('generic.confirm')}
        </Button>
      </div>
    </div>
  )
}

export default ConfirmModal
