import { ApolloProvider } from '@apollo/client'
import * as React from 'react'

import { useApollo } from '~shared/services/apollo/apollo'

const NetworkProvider: React.FC = ({ children }) => {
  const apolloClient = useApollo()

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default NetworkProvider
