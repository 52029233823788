import { AnimatePresence, motion } from 'framer-motion'

import Loading from '../Loading/Loading'
import WithWiseLogo from '../WithWiseLogo/WithWiseLogo'

interface Props {
  enabled: boolean
}

export default function LoadingOverlay({ enabled = true }: Props) {
  return (
    <AnimatePresence>
      {enabled ? (
        <motion.div
          role='dialog'
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className='absolute inset-0 z-50 flex h-full w-full origin-center items-center justify-center bg-gradient-to-tr from-[#52297c] via-purple to-[#b744ca]'
        >
          <div className='isolate w-full max-w-150'>
            <div className='flex w-full flex-col items-center justify-center gap-12 opacity-100'>
              <WithWiseLogo color='white' />
              <Loading colour='white' />
            </div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}
