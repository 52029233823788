import {
  ApolloCache,
  InMemoryCache,
  NormalizedCacheObject,
  TypePolicy,
} from '@apollo/client'
import generatedIntrospection from '@wise/graphql'
import { isNonEmptyString } from '@wise/utils'

export type ApolloCacheType = NormalizedCacheObject

const cacheIfNonEmptyId: TypePolicy = {
  keyFields: (o) => (isNonEmptyString(o.id) ? ['id'] : false),
}

export const createApolloCache = (): ApolloCache<ApolloCacheType> =>
  new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Dashboard: {
        keyFields: false,
      },
      RightToWork: {
        keyFields: ['rtwType'],
      },
      Payrun: cacheIfNonEmptyId,
      Summary: cacheIfNonEmptyId,
      Detail: cacheIfNonEmptyId,
      ModulrDocument: {
        keyFields: ['path'],
      },
      AmazonFlexDeliveryServicePartner: {
        keyFields: ['mcId'],
      },
      Cap: {
        keyFields: false,
      },
    },
  })
