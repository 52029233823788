import { WISE_SALES_ADMIN_ROLE } from './roles'
import { GatekeeperCheck } from './useGatekeeper'

const isNotWiseSalesAdmin: GatekeeperCheck = {
  role: { NOT: WISE_SALES_ADMIN_ROLE },
}

export const CAN_INSTANT_PAY: GatekeeperCheck = {
  userPermission: 'PAYRUN_PAY',
}
export const CAN_VIEW_SCHEDULING: GatekeeperCheck = isNotWiseSalesAdmin
export const CAN_ADD_MC_USERS: GatekeeperCheck = isNotWiseSalesAdmin
export const CAN_VIEW_PAYMENTS_DASHBOARD: GatekeeperCheck = {
  AND: [isNotWiseSalesAdmin, { userPermission: 'PAYRUNS_DASHBOARD' }],
}

export const CAN_VIEW_PAYMENTS: GatekeeperCheck = {
  AND: [
    isNotWiseSalesAdmin,
    {
      OR: [
        { userPermission: 'PAYRUNS_DASHBOARD' },
        { userPermission: 'PAYRUN_VIEW' },
      ],
    },
  ],
}

export const CAN_VIEW_USERS_LIST: GatekeeperCheck = {
  OR: [
    { legacyPermission: 'wise.user.list' },
    { legacyPermission: 'mainContractor.user.list' },
  ],
}

export const CAN_VIEW_MAINCONTRACTORS_LIST: GatekeeperCheck = {
  legacyPermission: 'wise.mainContractor.list',
}

export const CAN_VIEW_CUSTOMERS_LIST: GatekeeperCheck = {
  legacyPermission: 'wise.customer.list',
}

export const CAN_DELETE_PAYRUNS: GatekeeperCheck = {
  userPermission: 'PAYRUN_DELETE',
}

export const CAN_SET_PAYMENT_START_DATE: GatekeeperCheck = {
  userPermission: 'PAYRUN_START_DATE_CREATE',
}

export const CAN_OFFBOARD_DRIVERS: GatekeeperCheck = {
  userPermission: 'USER_OFFBOARD',
}

export const CAN_MARK_APPLICATION_AS_NON_STARTER: GatekeeperCheck = {
  userPermission: 'APPLICATION_ACTION_NON_STARTER',
}

export const CAN_APPROVE_REJECT_EVIDENCES: GatekeeperCheck = {
  userPermission: 'APPLICATION_ACTION',
}

export const CAN_PASS_FAIL_BG_TOX_TASKS: GatekeeperCheck = {
  userPermission: 'APPLICATION_ACTION',
}

export const CAN_PASS_FAIL_INDUCTION: GatekeeperCheck = {
  userPermission: 'INDUCTION_ACTION',
}

export const CAN_VIEW_DEBUG: GatekeeperCheck = {
  userPermission: `WISE_DEBUG`,
}

export const CAN_VIEW_BACK_OFFICE: GatekeeperCheck = {
  userPermission: `WISE_BACK_OFFICE`,
}

export const CAN_VIEW_FEATURE_FLAG: GatekeeperCheck = {
  userPermission: `WISE_FEATURE_FLAG`,
}

export const CAN_DOWNLOAD_SC_INVOICE_BUNDLE: GatekeeperCheck = {
  OR: [
    // Wise user, and has the USER_INVOICES permission
    {
      AND: [{ userType: 'WISE' }, { userPermission: 'USER_INVOICES' }],
    },
    // MC user, and has the MC INVOICES VIEW permission
    {
      AND: [
        { userType: 'MAINCONTRACTOR' },
        { userPermission: 'MAINCONTRACTOR_INVOICES_VIEW' },
      ],
    },
  ],
}

export const CAN_VIEW_VAT_HISTORY_LOG: GatekeeperCheck = {
  userPermission: 'TAX_DETAILS_HISTORY_VIEW',
}

/**
 * Incidents permissions
 * */
export const CAN_CREATE_INCIDENT: GatekeeperCheck = {
  userPermission: 'INCIDENT_CREATE',
}
export const CAN_VIEW_INCIDENT: GatekeeperCheck = {
  userPermission: 'INCIDENT_VIEW',
}
export const CAN_UPDATE_INCIDENT: GatekeeperCheck = {
  userPermission: 'INCIDENT_UPDATE',
}
export const CAN_DELETE_INCIDENT: GatekeeperCheck = {
  userPermission: 'INCIDENT_DELETE',
}
export const CAN_ACCESS_INCIDENTS_OPTIONS: GatekeeperCheck = {
  OR: [
    { userPermission: 'INCIDENT_VIEW' },
    { userPermission: 'INCIDENT_UPDATE' },
    { userPermission: 'INCIDENT_DELETE' },
  ],
}

/**
 * Deductions permissions
 */
export const CAN_CREATE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'DEDUCTION_CREATE',
}

export const CAN_CREATE_DEDUCTION_INSTALMENT: GatekeeperCheck = {
  userPermission: 'DEDUCTION_INSTALMENT_CREATE',
}

export const CAN_DELETE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'DEDUCTION_DELETE',
}

export const CAN_VIEW_DEDUCTION: GatekeeperCheck = {
  userPermission: 'DEDUCTION_VIEW',
}

export const CAN_UPDATE_DEDUCTION: GatekeeperCheck = {
  userPermission: 'DEDUCTION_UPDATE',
}

export const CAN_MIGRATE_SECURITAX_PRODUCT_TO_BEAMIN: GatekeeperCheck = {
  userPermission: 'WISE_USER_PRODUCT_SECURITAX_BEAMIN_MIGRATOR',
}
