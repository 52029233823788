import * as React from 'react'

import { EmptyProps } from '../../types'

export const createComponent = <
  Props extends EmptyProps,
  PartialProps extends Partial<Props>,
>(
  Component: React.ComponentType<Props>,
  props: PartialProps,
  displayName = Component.displayName,
): ((props: Omit<Props, keyof PartialProps>) => JSX.Element) => {
  const HOCComponent = (
    extraProps: Omit<Props, keyof PartialProps>,
  ): JSX.Element => {
    const allProps = React.useMemo<Props>(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      () => ({ ...extraProps, ...props }),
      [extraProps],
    )
    return <Component {...allProps} />
  }
  HOCComponent.displayName = displayName
  return HOCComponent
}
