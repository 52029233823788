import { AnimatePresence, motion } from 'framer-motion'
import * as React from 'react'

interface Props {
  testId?: string
}

const FormError: React.FC<Props> = ({ children, testId }) => {
  const wrapped = React.useMemo(() => {
    if (typeof children === 'string')
      return <p data-testid={testId}>{children}</p>
    return <div data-testid={testId}>{children}</div>
  }, [children, testId])

  return (
    <AnimatePresence>
      {children ? (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          className='mx-3 overflow-hidden rounded-b bg-red-light text-xs text-red-dark'
        >
          <div className='p-3'>{wrapped}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default FormError
