import { isApolloError } from '@apollo/client'
import { isDefined, randomString } from '@wise/utils'
import cx from 'classnames'
import * as React from 'react'

import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'

import Button from '../../../Buttons/Button/Button'
import { Modal, popModal, pushModal, removeModal } from '../../useModal'

interface ErrorProps {
  id?: string
  title?: string
  description?: React.ReactNode
  error?: unknown
  reportError?: boolean
  containerClassName?: string
}

const ErrorModal = ({
  id,
  title,
  description,
  error,
  reportError = true,
  containerClassName,
}: ErrorProps): JSX.Element => {
  React.useEffect(() => {
    if (reportError && isDefined(error)) {
      if (error instanceof Error) {
        if (isApolloError(error) && error.networkError) {
          notify(error.networkError)
        }

        notify(error)
      }
    }
  }, [reportError, error])

  return (
    <div
      className={cx(
        'rounded-xl bg-white p-6 shadow-md sm:w-96',
        containerClassName,
      )}
    >
      <h1 className={cx('mb-2 text-2xl font-bold', 'text-red')}>
        {title ?? 'Uh oh!'}
      </h1>
      <p className='mb-3 overflow-hidden text-sm font-bold'>
        {description ?? 'There was a problem!'}
      </p>
      {error instanceof Error ? (
        <p
          data-testid='document-already-re-issued-message'
          className='mb-3 overflow-hidden whitespace-pre-wrap break-words rounded-sm border-l-4 border-red bg-red/5 p-1 pl-2 text-sm font-light'
        >
          {error.message}
        </p>
      ) : null}
      <Button
        testId='close-error-message-modal'
        type='submit'
        className={cx(
          'mt-3 rounded-full bg-red px-6 py-2 text-center text-xl font-bold text-white',
        )}
        onClick={() => (id !== undefined ? removeModal(id) : popModal())}
      >
        Close.
      </Button>
    </div>
  )
}

export const createErrorModal = (
  options: Omit<ErrorProps, 'id'> & Partial<Pick<ErrorProps, 'id'>>,
): Modal => {
  const id = options.id ?? randomString()
  return { id, component: <ErrorModal {...options} id={id} /> }
}

export const triggerErrorModal = (
  options: Omit<ErrorProps, 'id'> & Partial<Pick<ErrorProps, 'id'>>,
): void => {
  pushModal(createErrorModal(options))

  if (options.error instanceof Error) {
    leaveBreadcrumb('Error Modal', { message: options.error?.message }, 'error')
  }
}
