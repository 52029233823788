/* eslint-disable @typescript-eslint/ban-ts-comment */
import { isClientSide } from '~shared/services/context'

import pkg from '../../../package.json'

export const APP_VERSION = pkg.version

export const APP_BUILD_TAG = process.env.NEXT_PUBLIC_BUILD_TAG
export const APP_BUILD_SHA = process.env.NEXT_PUBLIC_BUILD_SHA

if (isClientSide()) {
  // @ts-ignore
  window.appVersion = APP_VERSION
  // @ts-ignore
  window.appBuildTag = APP_BUILD_TAG
  // @ts-ignore
  window.appBuildSha = APP_BUILD_SHA
}
