import type { ParsedFeatureFlags } from '@wise/kv'
import type { Reducer } from 'react'

type FeatureFlagsAction = null | {
  type: (typeof REDUCER_ACTION)[keyof typeof REDUCER_ACTION]
  data: ParsedFeatureFlags
}

export const REDUCER_ACTION = {
  setFeatureFlags: 'SET_FEATURE_FLAGS',
} as const

export const featureFlagReducer: Reducer<
  ParsedFeatureFlags,
  FeatureFlagsAction
> = (state, action) => {
  switch (action?.type) {
    case REDUCER_ACTION.setFeatureFlags:
      return {
        ...state,
        ...action.data,
      }
    default:
      return state
  }
}
