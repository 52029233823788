import create from 'zustand'

import { FirebaseUser } from './Authenticator'

type AuthStore = {
  sessionToken: string | undefined
  userId: string | undefined
  firebaseUser: FirebaseUser | undefined
  lastAuthUpdate: string | undefined

  set: (
    state: Pick<AuthStore, 'sessionToken' | 'userId' | 'firebaseUser'>,
  ) => void

  reset: () => void
}

const useAuthStore = create<AuthStore>((set) => ({
  sessionToken: undefined,
  userId: undefined,
  firebaseUser: undefined,
  lastAuthUpdate: undefined,

  set: (state) => set({ ...state, lastAuthUpdate: new Date().toISOString() }),
  reset: () =>
    set({
      sessionToken: undefined,
      userId: undefined,
      firebaseUser: undefined,
    }),
}))

export default useAuthStore
