import { CheckOnboardingInviteCodeDocument } from '@wise/graphql'
import { invariant, isDefined, isNonEmptyString } from '@wise/utils'
import Router from 'next/router'

import { rootApolloClient } from '~shared/services/apollo/apollo'

import { OnboardingStageEnum } from '@/mc-onboarding/middleware/type'
import { hasPassedKYC } from '@/mc-onboarding/utils/kycStatus'

import { DefaultOptions, MiddlewareHandler } from '../../types'

import useMcOnboardingStore from './store'

type Options = Omit<DefaultOptions, 'wrapper'> & { stage: OnboardingStageEnum }

export const mcOnboarding = (options?: Options): MiddlewareHandler => ({
  fn: async (ctx) => {
    try {
      const onboardingData =
        'mcOnboardingData' in ctx.auth ? ctx.auth.mcOnboardingData : undefined
      const mainContractor = onboardingData?.mainContractor

      invariant(options?.stage, 'Stage is required')

      switch (options.stage) {
        case OnboardingStageEnum.CreateAccount: {
          const code = Router.query.code || useMcOnboardingStore.getState().code
          invariant(
            isNonEmptyString(code),
            'Your invite code was missing or malformed.',
          )
          const email = (
            await rootApolloClient.query({
              query: CheckOnboardingInviteCodeDocument,
              variables: { code },
            })
          ).data.checkInviteCode.email
          invariant(isNonEmptyString(email), 'Your invite code was invalid.')

          // Put the code & email in our store
          useMcOnboardingStore.getState().setCodeAndEmail(code, email)

          return { status: 'ok' }
        }
        case OnboardingStageEnum.SetupCompany: {
          invariant(isDefined(onboardingData), 'No onboarding data found.')
          invariant(
            !isDefined(mainContractor),
            'Main contractor already setup.',
          )
          return { status: 'ok' }
        }
        case OnboardingStageEnum.Contracts: {
          // Needs onboarding data, with maincontractor, but NO signature
          invariant(isDefined(onboardingData), 'No onboarding data found.')
          invariant(isDefined(mainContractor), 'Main contractor not setup.')
          invariant(
            !isDefined(mainContractor?.signature),
            'Main contractor already setup contracts.',
          )
          return { status: 'ok' }
        }
        case OnboardingStageEnum.ModulrHold: {
          invariant(isDefined(onboardingData), 'No onboarding data found.')
          invariant(isDefined(mainContractor), 'Main contractor not setup.')
          invariant(
            isDefined(mainContractor?.signature),
            'Main contractor already setup contracts.',
          )
          invariant(
            !hasPassedKYC(onboardingData.kycStatus),
            'KYC already passed.',
          )
          return { status: 'ok' }
        }
        default:
          return { status: 'ok' }
      }
    } catch (error) {
      return { status: 'redirect' }
    }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: undefined,
})
