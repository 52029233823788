import { invariant, isDefined, isNonEmptyString } from '@wise/utils'
import { castArray } from 'lodash'
import Router, { useRouter } from 'next/router'
import * as React from 'react'

import { RoutePaths } from '~generated/routes'
import { useFeatureFlags } from '~shared/featureFlags'
import { useMode } from '~shared/hooks/useMode'
import { parsePermission } from '~shared/permissions/permissions'
import { leaveBreadcrumb, notify } from '~shared/services/bugsnag/client'
import { useAuth } from '~shared/services/firebase/auth/hooks'

import {
  getMainContractorFromUser,
  useMaincontractor,
} from '@/maincontractors/hooks/useMaincontractor'
import { useModulrPayments } from '@/payments/hooks/useModulrPayments'

import { APPLICATION_MIDDLEWARE_MAP } from './map'
import { getNextRoute } from './nextRoute'
import {
  MiddlewareHandler,
  MiddlewarePayload,
  MiddlewareResponse,
  MiddlewareStatus,
  MiddlewareWrapper,
} from './types'

type Props = {
  children: React.ReactNode
}

const resolveMiddleware = async (
  middleware: MiddlewareHandler | MiddlewareHandler[],
  payload: MiddlewarePayload,
): Promise<MiddlewareResponse> => {
  const list = Array.isArray(middleware) ? middleware : [middleware]
  for (const item of list) {
    let result = item.fn(payload)
    if (result instanceof Promise) {
      result = await result
    }
    if (result.status !== 'ok') return result
  }
  return { status: 'ok' }
}

const MiddlewareContext = React.createContext<MiddlewarePayload | null>(null)

export const useMiddlewareContext = (): MiddlewarePayload => {
  const payload = React.useContext(MiddlewareContext)
  invariant(isDefined(payload), 'MiddlewareContext is not defined')
  return payload
}

export const MiddlewareProvider = ({ children }: Props) => {
  const [status, setStatus] = React.useState<MiddlewareStatus>({
    status: 'loading',
  })
  const auth = useAuth()
  const mode = useMode()
  const router = useRouter()
  const mc = useMaincontractor()
  const featureFlags = useFeatureFlags()
  const mainContractorId = React.useMemo(() => {
    const possibleIds = [mc?.id, router.query.mcId]
    const id = possibleIds.find(isNonEmptyString)
    return id
  }, [mc?.id, router.query.mcId])
  const modulrInfo = useModulrPayments({ mainContractorId })

  const middlewares = React.useMemo<MiddlewareHandler[]>(() => {
    const asPath = router.pathname
    const result = APPLICATION_MIDDLEWARE_MAP[asPath as RoutePaths]
    if (!result) return []
    return castArray(result)
  }, [router.pathname])

  const middlewarePayload = React.useMemo<MiddlewarePayload>(() => {
    const user = 'user' in auth ? auth.user : undefined
    const permissions =
      user?.permissions?.filter(isDefined).map(parsePermission) ?? []

    const mainContractor = getMainContractorFromUser(user)

    return {
      auth,
      mode,
      permissions,
      mainContractor,
      featureFlags,
      modulrInfo,
      mainContractorId,
    }
  }, [auth, featureFlags, mainContractorId, mode, modulrInfo])

  React.useEffect(() => {
    if (auth.status === 'undetermined') return setStatus({ status: 'loading' })
    if (!middlewares) return setStatus({ status: 'ok' })
    const fn = async () => {
      try {
        setStatus({ status: 'loading' })
        const result = await resolveMiddleware(middlewares, middlewarePayload)
        // If we have a redirect result, we need to handle it now
        if (result.status === 'redirect') {
          const next =
            result.url ||
            getNextRoute({
              authState: middlewarePayload.auth,
              requestedUrl: Router.asPath,
            })
          if (next === undefined) return
          if (Router.asPath === next) {
            console.error(
              'Infinite redirect loop detected, allowing access to route as fail-safe',
            )
            return void setStatus({ status: 'ok' })
          }
          return void Router.replace(next)
        }

        return void setStatus(result)
      } catch (error) {
        console.error(error)
        leaveBreadcrumb('MiddlewareProvider', {
          error,
          issue:
            'An error was thrown that triggered a middleware failure & full reload',
        })
        notify(error)
      }
    }
    fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [middlewares, auth.status])

  const Wrapper = React.useMemo<MiddlewareWrapper>(() => {
    if (!middlewares) return React.Fragment
    return middlewares.reduce((Previous, middleware, ix) => {
      const Next = middleware.wrapper
      if (Next === undefined) return Previous
      const ReducedWrapper: MiddlewareWrapper = React.memo((props) => (
        <Previous>
          <Next {...props} />
        </Previous>
      ))
      ReducedWrapper.displayName = `MiddlewareWrapper(${
        Next.displayName || Next.name
      })<${ix}>`
      return ReducedWrapper
    }, React.Fragment as MiddlewareWrapper)
  }, [middlewares])

  const render = React.useMemo(() => {
    if (!middlewares || status.status === 'ok') return true
    return middlewares.every(
      (middleware) => middleware.optimisticRender === true,
    )
  }, [middlewares, status.status])

  return render ? (
    <MiddlewareContext.Provider value={middlewarePayload}>
      <Wrapper>{children}</Wrapper>
    </MiddlewareContext.Provider>
  ) : null
}
