import mitt from 'mitt'
import * as React from 'react'

export type EmitterTypes = {
  AmazonFlexCredentialsVerification: void
  DriverSearch: string
  RefreshApplications: void
  RefreshDrivers: void
  RefreshDeductions: void
  RefreshMainContractorDepots: void
  RefreshReport: void
  RefreshActiveMainContractors: void
  RefreshRegions: void
  WorklogRefreshed: void
}

export const Emitter = {
  AmazonFlexCredentialsVerification: 'AmazonFlexCredentialsVerification',
  DriverSearch: 'DriverSearch',
  RefreshActiveMainContractors: 'RefreshActiveMainContractors',
  RefreshApplications: 'RefreshApplications',
  RefreshDeductions: 'RefreshDeductions',
  RefreshDrivers: 'RefreshDrivers',
  RefreshMainContractorDepots: 'RefreshMainContractorDepots',
  RefreshReport: 'RefreshReport',
  RefreshRegions: 'RefreshRegions',
  WorklogRefreshed: 'WorklogRefreshed',
} as const

export const emitter = mitt<EmitterTypes>()

export type EmitterEvent = (typeof Emitter)[keyof typeof Emitter]

export const useEmittedEvent = <T extends keyof EmitterTypes>(
  event: T,
  callback: (data: EmitterTypes[T]) => void,
): void => {
  const cb = React.useRef(callback)

  React.useEffect(() => {
    cb.current = callback
  }, [callback])

  React.useEffect(() => {
    const fn = cb.current
    emitter.on(event, fn)
    return () => emitter.off(event, fn)
  }, [event])
}
