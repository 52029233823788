import { parse, stringify } from 'query-string'

export const stringifyQueryParams = (params: Record<string, unknown>): string =>
  stringify(params, {
    arrayFormat: 'comma',
    skipEmptyString: true,
    skipNull: true,
  })

export const parseQueryParams = (params: string): Record<string, unknown> =>
  parse(params, {
    arrayFormat: 'comma',
    parseBooleans: true,
    parseNumbers: true,
  })
