import Link from 'next/link'

const Error = (): JSX.Element => {
  return (
    <>
      <div className='flex h-full w-full items-center justify-center bg-purple text-white'>
        <div className='flex max-w-[600px] flex-col items-center justify-center px-8'>
          <h1 className='text-3xl'>Uh, oh!</h1>
          <p className='pt-4 text-center font-light'>
            It looks like something went wrong somewhere! This error has
            automatically been reported to our engineering team! Please click
            the button below to reload the page!
          </p>
          <Link href='/'>
            <a className='mt-6 rounded-full border-2 bg-purple py-2 px-6 text-sm font-bold shadow-md'>
              Go to Dashboard
            </a>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Error
