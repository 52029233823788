import create from 'zustand'

type MCOnboardingStore = {
  email: string | null
  code: string | null
  setCode: (code: string | null) => void
  setEmail: (email: string | null) => void
  setCodeAndEmail: (code: string | null, email: string | null) => void
}

const useMcOnboardingStore = create<MCOnboardingStore>((set) => ({
  code: null,
  email: null,

  setCode: (code: string | null) => set({ code }),
  setEmail: (email: string | null) => set({ email }),
  setCodeAndEmail: (code: string | null, email: string | null) =>
    set({ code, email }),
}))

export default useMcOnboardingStore
