import * as React from 'react'
import TagManager from 'react-gtm-module'

import { GOOGLE_TAG_MANAGER_KEY } from '~shared/config/gtm'

export const useGoogleTagManager = (): void => {
  React.useEffect(() => {
    if (!GOOGLE_TAG_MANAGER_KEY) return
    TagManager.initialize({
      gtmId: GOOGLE_TAG_MANAGER_KEY,
    })
  }, [])
}
