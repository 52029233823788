import { removeToast, showToastNotification } from '@wise/utils'
import { t } from 'i18next'

import Loading from '~shared/components/Loading/Loading'
import { Emitter, emitter } from '~shared/services/emitter'
import { trpcProxy } from '~shared/services/trpc'
import { Time } from '~shared/utils/Time'

import { WorklogEventOutput } from './validator'

interface PolymorphicWorklogEvent<
  Type extends WorklogEventOutput['data']['type'],
  Data,
> {
  type: Type
  data: Data
  toastId: string
  timeoutId: number
}

type PushedToastQueue = (ManualLogin | ManualWorklogRefresh)[]

type ManualLogin = PolymorphicWorklogEvent<'manual_login', ManualLoginData>
type ManualWorklogRefresh = PolymorphicWorklogEvent<
  'manual_worklog_refresh',
  ManualWorklogRefreshData
>

type ManualLoginData = {
  mcId: string
  timeoutDuration: Time
}

type ManualWorklogRefreshData = {
  scrapeId: string
  mcId: string
  depotId: string
  depotName: string
  week: number
  year: number
  timeoutDuration: Time
}

export class WorklogEvents {
  private static queue: PushedToastQueue = []

  public static onDisconnect() {
    // Delete all pending toasts if disconnected, as we will never receive the events!
    this.queue.forEach((item) => removeToast(item.toastId))
    this.queue = []
  }

  public static handler(
    log: (...args: unknown[]) => void,
    data: WorklogEventOutput,
  ) {
    log(`Handling "new-toast" event with type of "${data.type}"`)

    switch (data.data.type) {
      case 'manual_worklog_refresh':
        return this.handleManualWorklogRefresh(data)
      case 'manual_login':
        return this.handleManualLogin(data)
      default:
        return
    }
  }

  private static handleManualWorklogRefresh(data: WorklogEventOutput) {
    const scrapeId = data.data.scrapeId

    const queueIndex = this.queue.findIndex(
      (item) =>
        item.type === 'manual_worklog_refresh' &&
        item.data.scrapeId === scrapeId,
    )

    if (queueIndex === -1) {
      // We weren't listening for this scrape, so let's sort of ignore it
      // but still trigger a worklog refresh just incase.
      return emitter.emit(Emitter.WorklogRefreshed)
    }

    const refreshData = this.queue[queueIndex].data as ManualWorklogRefreshData
    removeToast(this.queue[queueIndex].toastId)
    clearTimeout(this.queue[queueIndex].timeoutId)
    this.queue.splice(queueIndex, 1)

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'WorklogRefreshComplete',
      properties: {
        ...refreshData,
        wsEventData: data.data,
      },
    })

    switch (data.data.isSuccess) {
      case true:
        showToastNotification({
          type: 'success',
          description: t('worklog.work_history.refresh_success', {
            name: refreshData.depotName,
            week: refreshData.week,
            year: refreshData.year,
          }),
        })
        emitter.emit(Emitter.WorklogRefreshed)
        break
      case false:
        showToastNotification({
          type: 'error',
          description: t('worklog.work_history.refresh_error'),
        })
        break
    }
  }

  private static handleManualLogin(data: WorklogEventOutput) {
    // Find the first pending toast with that type
    const queueIndex = this.queue.findIndex((i) => i.type === 'manual_login')

    if (queueIndex !== -1) {
      removeToast(this.queue[queueIndex].toastId)
      clearTimeout(this.queue[queueIndex].timeoutId)
      this.queue.splice(queueIndex, 1)
    }

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AmazonFlexCredentialsVerified',
      properties: {
        wsEventData: data.data,
      },
    })

    return emitter.emit(Emitter.AmazonFlexCredentialsVerification)
  }

  public static listenForCredentialVerification(data: ManualLoginData) {
    const toastId = showToastNotification({
      type: 'custom',
      duration: Infinity,
      description: (
        <div className='flex max-w-350 flex-row items-center gap-3'>
          <Loading variant='small' className='mr-3' />
          <div className='flex flex-col'>
            <p className='font-bold'>
              {t('worklog.amazon.update_flex_details_success_pending_title')}
            </p>
            <p className='text-xs font-light'>
              {t(
                'worklog.amazon.update_flex_details_success_pending_description',
              )}
            </p>
          </div>
        </div>
      ),
    })

    const timeoutId = window.setTimeout(() => {
      WorklogEvents.timedOut(toastId)
    }, data.timeoutDuration.toMs())

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'AmazonFlexCredentialsVerificationRequested',
      properties: { ...data },
    })

    this.queue.push({
      type: 'manual_login',
      toastId,
      data,
      timeoutId,
    })
  }

  public static listenForScrapeComplete(data: ManualWorklogRefreshData) {
    const toastId = showToastNotification({
      type: 'custom',
      duration: Infinity,
      description: (
        <div className='flex max-w-350 flex-row items-center gap-3'>
          <Loading variant='small' className='mr-3' />
          <div className='flex flex-col'>
            <p className='font-bold'>
              {t('worklog.work_history.refresh_pending_title')}
            </p>
            <p className='text-xs font-light'>
              {t('worklog.work_history.refresh_pending_description', {
                name: data.depotName,
                week: data.week,
                year: data.year,
              })}
            </p>
          </div>
        </div>
      ),
    })

    const timeoutId = window.setTimeout(() => {
      WorklogEvents.timedOut(toastId)
    }, data.timeoutDuration.toMs())

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'WorklogRefreshRequested',
      properties: { ...data },
    })

    this.queue.push({
      type: 'manual_worklog_refresh',
      toastId,
      data,
      timeoutId,
    })
  }

  /**
   * Called when a pending worklog refresh times out
   */
  private static timedOut(toastId: PushedToastQueue[number]['toastId']) {
    const queueIndex = this.queue.findIndex((item) => item.toastId === toastId)

    if (queueIndex === -1) {
      // A toast timed out that doesn't exist anymore, just ignore
      return
    }

    // Remove the pending toast, and cancel the timeout (if not already cleared)
    const payload = this.queue[queueIndex]
    removeToast(payload.toastId)
    clearTimeout(payload.timeoutId)

    this.queue.splice(queueIndex, 1)

    showToastNotification({
      type: 'error',
      description: t('worklog.work_history.refresh_timeout'),
    })

    trpcProxy.services.mixpanel.trackEvent.mutate({
      name: 'WorklogEventTimeout',
      properties: { ...payload.data },
    })
  }
}
