import cx from 'classnames'

interface Props {
  className?: JSX.IntrinsicElements['button']['className']
  onClick?: JSX.IntrinsicElements['button']['onClick']
}

const CloseModalButton = ({ className, onClick }: Props): JSX.Element => {
  return (
    <button
      type='button'
      aria-label='Close Modal'
      data-testid='close-modal-button'
      className={cx(
        'rounded-full border-[3px] p-1.5 text-black/75 hover:border-red hover:bg-red hover:text-white hover:shadow-lg focus:border-red focus:bg-red focus:text-white',
        className ? className : 'border-red-dark text-red-dark',
      )}
      onClick={onClick}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlSpace='preserve'
        viewBox='0 0 36 36'
        width={12}
        height={12}
      >
        <g id='icons'>
          <path
            id='close_1_'
            fill='currentColor'
            d='M6.2 3.5 3.5 6.2c-.7.7-.7 1.9 0 2.7l9.2 9.2-9.2 9.2c-.7.7-.7 1.9 0 2.7l2.6 2.6c.7.7 1.9.7 2.7 0l9.2-9.2 9.2 9.2c.7.7 1.9.7 2.7 0l2.6-2.6c.7-.7.7-1.9 0-2.7L23.3 18l9.2-9.2c.7-.7.7-1.9 0-2.7l-2.6-2.6c-.7-.7-1.9-.7-2.7 0L18 12.7 8.8 3.5c-.7-.7-1.9-.7-2.6 0z'
          />
        </g>
      </svg>
    </button>
  )
}

export default CloseModalButton
