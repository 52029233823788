interface HasErrorCode {
  code: string
}

export const isHasErrorCode = (item: unknown): item is HasErrorCode => {
  return (
    Boolean(item) &&
    typeof item === 'object' &&
    typeof (item as Partial<HasErrorCode>).code === 'string'
  )
}

export const DEFAULT_ERROR_MESSAGE =
  'An unknown error occured. Please try again.'

export const FirebaseErrorCodes = {
  USER_DISABLED: 'auth/user-disabled',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  NETWORK_REQUEST_FAILED: 'auth/network-request-failed',
  INVALID_EMAIL: 'auth/invalid-email',
} as const

export const getUserErrorMessage = (err: unknown): string => {
  const code = isHasErrorCode(err) ? err.code : undefined
  switch (code) {
    case FirebaseErrorCodes.USER_DISABLED:
      return 'This user account is currently disabled.'
    case FirebaseErrorCodes.USER_NOT_FOUND:
    case FirebaseErrorCodes.WRONG_PASSWORD:
      return 'Those details were incorrect. Please try again.'
    case FirebaseErrorCodes.INVALID_ACTION_CODE:
      return 'The action code is invalid. If you followed this link via email, it has expired and you will need to request a new link.'
    case FirebaseErrorCodes.NETWORK_REQUEST_FAILED:
      return 'There was a problem connecting to the server. Please try again.'
    case FirebaseErrorCodes.INVALID_EMAIL:
    default:
      return err instanceof Error ? err.message : DEFAULT_ERROR_MESSAGE
  }
}
