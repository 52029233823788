import { RoutePaths, route } from '~generated/routes'
import { AuthenticatorState } from '~shared/services/firebase/auth/context'

import { needsOnboardingRoute } from '@/mc-onboarding/middleware/MCOnboarding'

type NextRouteOptions = {
  authState: AuthenticatorState
  requestedUrl: string
}

const NOT_ALLOWED_REDIRECTS = ['/', '/logout', '/auth/change-password']

export const getNextRoute = ({
  authState,
  requestedUrl,
}: NextRouteOptions): RoutePaths | string | undefined => {
  if (authState.status === 'undetermined') return undefined
  const mcOnboardingData =
    'mcOnboardingData' in authState ? authState.mcOnboardingData : undefined
  const onboardingRoute = needsOnboardingRoute(mcOnboardingData)
  if (onboardingRoute !== null) return onboardingRoute

  switch (authState.status) {
    case 'authenticated':
      return '/'
    case 'guest': {
      const param = !NOT_ALLOWED_REDIRECTS.includes(requestedUrl)
        ? `?path=${encodeURIComponent(requestedUrl)}`
        : ''
      return `${route('/login')}${param}`
    }
  }
}
