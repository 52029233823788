import { isDefined } from '@wise/utils'
import * as React from 'react'

import { AuthenticatedUserState } from '~shared/services/firebase/auth/context'
import { useUser } from '~shared/services/firebase/auth/hooks'

export const getMainContractorFromUser = (
  user: AuthenticatedUserState['user'] | undefined,
) =>
  user?.gigs?.find((gig) => isDefined(gig?.mainContractor))?.mainContractor ??
  null

export type MainContractorFromUser = ReturnType<
  typeof getMainContractorFromUser
>

export type UseMainContractorReturn = ReturnType<typeof useMaincontractor>
export const useMaincontractor = () => {
  const user = useUser()

  const maincontractor = React.useMemo(
    () => getMainContractorFromUser(user),
    [user],
  )

  return maincontractor ?? null
}
