import * as React from 'react'

import {
  AuthContext,
  AuthenticatedUserState,
  AuthenticatorState,
} from './context'

export const useAuth = (): AuthenticatorState => React.useContext(AuthContext)
export const useUser = (): AuthenticatedUserState['user'] | undefined => {
  const result = useAuth()
  return 'user' in result ? result.user : undefined
}
export const useUserPermissions = ():
  | AuthenticatedUserState['userPermissions']
  | undefined => {
  const result = useAuth()
  return 'userPermissions' in result ? result.userPermissions : undefined
}
export const useMCOnboardingData = ():
  | AuthenticatedUserState['mcOnboardingData']
  | undefined => {
  const result = useAuth()
  return 'mcOnboardingData' in result ? result.mcOnboardingData : undefined
}
