export enum SocketEvent {
  // Socket.IO events
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',

  // Room events
  ASSIGNED_ROOM = 'assigned-room',
  ASSIGNED_USER_ROOM = 'assigned-user-room',

  // Custom events
  NEW_NOTIFICATION = 'new-notification',
  NEW_TOAST = 'new-toast',
}
