import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const auth = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    return ctx.auth.status === 'authenticated'
      ? { status: 'ok' }
      : { status: 'redirect', url: options?.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
