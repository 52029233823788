import * as React from 'react'
import { twMerge } from 'tailwind-merge'

import useTheme from '~shared/hooks/useTheme'

interface Props {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'span'
  margin?: boolean
  className?: string
  testId?: string
}

const ModalHeader: React.FC<Props> = React.memo(
  ({ testId, className, children, margin = true, ...props }) => {
    const theme = useTheme()

    const Tag: keyof JSX.IntrinsicElements = props.as || 'h1'

    return (
      <Tag
        data-testid={testId}
        className={twMerge(
          'text-2xl font-bold text-black',
          margin && 'mb-2',
          theme === 'purple' ? 'text-purple-dark' : 'text-green',
          className,
        )}
      >
        {children}
      </Tag>
    )
  },
)

ModalHeader.displayName = 'ModalHeader'

export default ModalHeader
