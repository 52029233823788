export const copyToClipboard = (t: string): void => {
  const el = document.createElement('textarea')
  el.style.position = 'absolute'
  el.style.left = '0px'
  el.style.top = '0px'
  el.value = t
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}
