type LogTopic =
  | 'apolloClient'
  | 'Authenticator'
  | 'FeatureFlags'
  | 'remoteConfig'
  | 'useMaintenanceMode'
  | 'useNotificationManager'
  | 'useSchedule'
  | 'useVersionCheck'
  | `Auth Middleware`
  | `Guest Middleware`
  | 'ws'

const ColourMap: Record<LogTopic, string> = {
  'Auth Middleware': 'red',
  'Guest Middleware': 'red',
  apolloClient: 'green',
  Authenticator: 'hotpink',
  FeatureFlags: 'yellow',
  remoteConfig: 'tomato',
  useMaintenanceMode: 'pink',
  useNotificationManager: 'cyan',
  useSchedule: 'magenta',
  useVersionCheck: 'grey',
  ws: 'orange',
}

export const log = (topic: LogTopic, ...args: unknown[]) => {
  console.debug(`%c[${topic}]`, `color:${ColourMap[topic]}`, ...args)
}

export const createLogger =
  (topic: LogTopic) =>
  (...args: unknown[]) =>
    log(topic, ...args)
