import { djs } from '@wise/utils'
import i18n, { TFuncKey, TFunction } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

import { APP_ENV } from '~shared/config/app'
import { isClientSide } from '~shared/services/context'

export const locales = [
  { code: 'en', flag: '🇬🇧', name: 'English' },
  { code: 'bg', flag: '🇧🇬', name: 'українська' },
  { code: 'pa', flag: '🇵🇰/🇮🇳', name: 'ਪੰਜਾਬੀ' },
  { code: 'pl', flag: '🇵🇱', name: 'Polski' },
  { code: 'pt', flag: '🇵🇹', name: 'Português' },
  { code: 'ro', flag: '🇷🇴', name: 'Română' },
  { code: 'uk', flag: '🇺🇦', name: 'українська' },
  // { code: 'cimode', flag: '🏴‍☠️' },
] as const

export type SupportedLanguages = Exclude<
  (typeof locales)[number]['code'],
  'cimode'
>

const getPath = (path: string) => {
  if (isClientSide()) return path
  return `http://localhost:3000${path}`
}

let i18nInstance: TFunction

export const TRANSLATIONS_ENABLED = APP_ENV !== 'production'

export const initialiseI18n = async () => {
  if (!i18nInstance) {
    i18nInstance = await i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        debug: false,
        fallbackLng: 'en',
        backend: {
          loadPath: getPath('/locales/{{lng}}.json'),
        },
        ns: ['common'],
        defaultNS: 'common',
        supportedLngs: TRANSLATIONS_ENABLED
          ? locales.map((l) => l.code)
          : ['en'],
        interpolation: {
          escapeValue: false,
        },
        react: {
          useSuspense: false,
        },
      })
  }

  i18n.services.formatter?.add('TIME_SIMPLE', (value) =>
    djs(value).locale(getCurrentLanguage()).format('dddd,  Do MMMM YYYY'),
  )

  i18n.services.formatter?.add('toLocaleString', (value, lng) =>
    typeof value === 'number' ? value.toLocaleString(lng) : value,
  )

  i18n.services.formatter?.add('DD/MM/YYYY', (value) =>
    djs(value).locale(getCurrentLanguage()).format('DD/MM/YYYY'),
  )

  return i18nInstance
}

export const getCurrentLanguage = (): SupportedLanguages => {
  try {
    for (const locale of locales) {
      if (i18n.language.startsWith(locale.code)) return locale.code
    }
    return 'en'
  } catch (error) {
    return 'en'
  }
}

export type TranslationKey =
  | TFuncKey<'common', undefined>
  | TemplateStringsArray extends infer A
  ? A
  : never
