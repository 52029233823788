import { ModalProps } from '~shared/components/Modal/useModal'
import ModalHeader from '~shared/components/UI/ModalHeader'

import ModulrTermsCheckbox from '@/maincontractors/components/CompanySettings/ModulrTermsCheckbox'
import { ModulrPaymentInfo } from '@/payments/hooks/useModulrPayments'

interface Props {
  modulrInfo: ModulrPaymentInfo
  onSuccess: () => void
}

const ModulrTermsModal = ({
  modulrInfo,
  onSuccess,
}: Props & ModalProps): JSX.Element => {
  return (
    <div className='max-w-400 rounded-xl bg-white p-12 text-left shadow-md'>
      <ModalHeader>Terms & Conditions.</ModalHeader>
      <p>
        In order to proceed to payments, you must read and agree to the terms
        and conditions.
      </p>
      <div className='mt-3'>
        <ModulrTermsCheckbox info={modulrInfo} onSuccess={onSuccess} />
      </div>
    </div>
  )
}

export default ModulrTermsModal
