import { invariant } from '@wise/utils'

import { PortalMode } from '~shared/hooks/useMode'

import { MiddlewareHandler } from '../types'

type Options = Pick<MiddlewareHandler, 'optimisticRender' | 'wrapper'> & {
  mode: PortalMode
  redirectTo?: string
}

export const mode = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    invariant(options?.mode, 'Mode is required')
    return ctx.mode === options.mode
      ? { status: 'ok' }
      : { status: 'redirect', url: options.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
