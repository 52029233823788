import create from 'zustand'

import { isServerSide } from '~shared/services/context'

import { parseQueryParams } from './qs'

interface SearchStore {
  search: string | null
  updateSearch: (path: string | null) => void
}

export const useSearchStore = create<SearchStore>((set) => ({
  search: null,
  updateSearch: (search) => set({ search }),
}))

export const getLatestQueryParams = (providedPath?: string) => {
  const fromStore = useSearchStore.getState().search
  const search = providedPath
    ? providedPath.split('?')[1]
    : isServerSide()
    ? fromStore
    : fromStore !== null
    ? fromStore
    : (window.location.search || '?').slice(1)
  return search ? parseQueryParams(search) : {}
}
