import cx from 'classnames'
import * as React from 'react'

import { Theme } from '~shared/hooks/useTheme'

import styles from './Loading.module.scss'

interface Props {
  colour?:
    | 'purple-dark'
    | 'grey-dark'
    | Theme
    | 'grey-darker'
    | 'red'
    | 'white'
    | 'black'
    | 'grey-800'
    | 'theme'
    | 'orange'
  variant?: 'small' | 'default' | 'chunky'
}

const Loading = ({
  colour = 'purple-dark',
  className,
  variant = 'default',
  ...props
}: Props & JSX.IntrinsicElements['div']): JSX.Element => {
  const borderClass = React.useMemo(() => {
    switch (colour) {
      case 'black':
        return 'border-black'
      case 'green':
        return 'border-green'
      case 'grey-800':
        return 'border-grey-800'
      case 'grey-dark':
        return 'border-grey-dark'
      case 'grey-darker':
        return 'border-grey-darker'
      case 'orange':
        return 'border-orange-300'
      case 'purple':
        return 'border-purple'
      case 'purple-dark':
        return 'border-purple-dark'
      case 'red':
        return 'border-red'
      case 'white':
        return 'border-white'
      case 'theme':
        return 'border-theme'
    }
  }, [colour])

  return (
    <div
      {...props}
      className={cx(
        'rounded-full',
        borderClass,
        variant === 'small' && 'h-4 w-4 border-4',
        variant === 'chunky' && 'h-10 w-10 border-8',
        variant === 'default' && 'h-8 w-8 border-4',
        styles.spinner,
        className,
      )}
    ></div>
  )
}

export default React.memo(Loading)
