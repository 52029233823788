import { useRouter } from 'next/router'
import * as React from 'react'

import { route } from '~generated/routes'
import { pushModal, removeModal } from '~shared/components/Modal/useModal'
import { useMode } from '~shared/hooks/useMode'
import { createComponent } from '~shared/utils/createComponent'

import ModulrTermsModal from '@/payments/components/modals/ModulrTermsModal'
import { ModulrPaymentInfo } from '@/payments/hooks/useModulrPayments'

import { useMiddlewareContext } from '../MiddlewareProvider'
import { MiddlewareWrapper } from '../types'

export const ModulrContext = React.createContext<ModulrPaymentInfo | null>(null)

const Modulr: MiddlewareWrapper = ({ children }) => {
  const router = useRouter()
  const mode = useMode()

  const { modulrInfo } = useMiddlewareContext()

  React.useEffect(() => {
    /**
     * Only show the overlay if;
     * - We are on the MCP
     * - We have receieved modulrInfo back
     * - They ahvent signed the T&Cs
     */
    if (mode === 'WAP' || modulrInfo.loading || modulrInfo.hasSignedTerms)
      return

    const asPath = router.asPath

    const modalId = 'must-accept-modulr-terms'
    pushModal({
      id: modalId,
      component: createComponent(ModulrTermsModal, {
        modulrInfo,
        onSuccess: () => {
          router.replace(asPath)
          removeModal(modalId)
        },
      }),
    })

    router.replace(route('/'))
  }, [mode, modulrInfo, router])

  return (
    <div>
      <ModulrContext.Provider value={modulrInfo ?? null}>
        {children}
      </ModulrContext.Provider>
    </div>
  )
}

export default Modulr
