import { UserType } from '@wise/graphql'

import { PORTAL_RUNTIME, PortalRuntime } from '~shared/config/app'

const PORTAL_RUNTIME_GROUPS: Record<PortalRuntime, UserType[]> = {
  MAINCONTRACTOR: ['WISE', 'MAINCONTRACTOR'],
  NETWORK: ['NETWORK'],
}

// What user types are allowed to login to the portal?
export const ALLOWED_USER_TYPES: UserType[] =
  PORTAL_RUNTIME_GROUPS[PORTAL_RUNTIME]
