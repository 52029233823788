import { hasPassedKYC } from '@/mc-onboarding/utils/kycStatus'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const hasPassedKyc = (options?: Options): MiddlewareHandler => ({
  fn: async ({ auth, mainContractor, mode }) => {
    const onboardingData =
      'mcOnboardingData' in auth ? auth.mcOnboardingData : undefined

    const isWap = mode === 'WAP'

    return isWap ||
      mainContractor?.status === 'ACTIVE' ||
      hasPassedKYC(onboardingData?.kycStatus)
      ? { status: 'ok' }
      : { status: 'redirect' }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: undefined,
})
