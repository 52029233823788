import { HTTPHeaders, createTRPCProxyClient, httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import { isNonEmptyString, randomString } from '@wise/utils'

import { APP_VERSION } from '~shared/config/version'

import { Authenticator } from '../firebase/auth/Authenticator'

import { MainRouter } from './router'

export const TRPC_TRACE_HEADER = 'x-wise-trace-id'
export const TRPC_CLIENT_VERSION_HEADER = 'x-wise-client-version'
export const TRPC_CLIENT_NAME_HEADER = 'x-wise-client-name'

const batchLink = httpBatchLink({
  url: '/api/trpc',
  async headers() {
    const headers: HTTPHeaders = {
      [TRPC_TRACE_HEADER]: randomString(),
      [TRPC_CLIENT_VERSION_HEADER]: APP_VERSION,
      [TRPC_CLIENT_NAME_HEADER]: '@wise/portal',
    }
    const token = await Authenticator.getSessionToken()
    if (isNonEmptyString(token)) headers.Authorization = `Bearer ${token}`
    return headers
  },
})

/**
 * The tRPC client that exposes query methods for use in non-React contexts
 * e.g. `const version = await trpcProxy.version()`
 */
export const trpcProxy = createTRPCProxyClient<MainRouter>({
  links: [batchLink],
})

/**
 * The tRPC client that exposes query methods for use in React components
 * e.g. `const version = trpc.version.useQuery()`
 */
export const trpc = createTRPCNext<MainRouter>({
  abortOnUnmount: true,
  overrides: {
    useMutation: {
      async onSuccess(opts) {
        await opts.originalFn()
        await opts.queryClient.invalidateQueries()
      },
    },
  },
  config() {
    return {
      links: [batchLink],
    }
  },
  ssr: false,
})
