import type { ParsedFeatureFlags } from '@wise/kv'
import React from 'react'

import { FeatureFlagContext } from '~shared/featureFlags/context'

type UseFeatureFlagsArg = keyof ParsedFeatureFlags | undefined

export type UseFeatureFlagsReturn<
  T extends UseFeatureFlagsArg = keyof ParsedFeatureFlags,
> = T extends keyof ParsedFeatureFlags
  ? ParsedFeatureFlags[T]
  : ParsedFeatureFlags

export function useFeatureFlags<T extends UseFeatureFlagsArg = undefined>(
  name?: T,
): UseFeatureFlagsReturn<T>
export function useFeatureFlags(
  name?: UseFeatureFlagsArg,
): UseFeatureFlagsReturn<undefined> | UseFeatureFlagsReturn {
  const context = React.useContext(FeatureFlagContext)

  return name ? context.flags[name] : context.flags
}
