import { z } from 'zod'

import { asyncDownloadTypes } from './types'

const createValidator = <T extends string, D extends z.AnyZodObject>(
  type: T,
  data: D,
) =>
  z.object({
    type: z.literal(type),
    data,
  })

const successSchema = z.object({ url: z.string().url(), traceId: z.string() })
const failedSchema = z.object({ traceId: z.string() })

/**
 * This automatically produces two schema (schemi?) for each
 * async download type,
 * - A "success" handler
 * - A "failed" handler
 * And ensures that the types for each are as expected (see above)
 */
const generatedSchemas = asyncDownloadTypes.flatMap((type) => {
  return [
    createValidator(`${type}_GENERATED`, successSchema),
    createValidator(`${type}_FAILED`, failedSchema),
  ]
})
type GeneratedSchema = (typeof generatedSchemas)[number]

// The full discrimanted union schema that will check what type we have
export const asyncDownloadSchema = z.discriminatedUnion(
  'type',
  generatedSchemas as [GeneratedSchema, ...GeneratedSchema[]],
)

export type AsyncDownloadSchema = z.infer<typeof asyncDownloadSchema>
export type AsyncDownloadOutcomeType = AsyncDownloadSchema['type']

export type AsyncDownloadSuccessType = Extract<
  AsyncDownloadSchema,
  { data: z.infer<typeof successSchema> }
>['type']

export type AsyncDownloadFailType = Exclude<
  AsyncDownloadOutcomeType,
  AsyncDownloadSuccessType
>

export type AsyncDownloadData<T extends AsyncDownloadOutcomeType> = Extract<
  AsyncDownloadSchema,
  { type: T }
>['data']

export type AsyncDownloadSchemaSuccess = Extract<
  AsyncDownloadSchema,
  { type: AsyncDownloadSuccessType }
>
