import produce from 'immer'

export const nullEmptyFields = <T extends Record<string, unknown>>(o: T): T =>
  produce(o, (draft) => {
    Object.keys(draft).forEach((key) => {
      const val = draft[key]
      if (val === '') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft[key] = null
      }
    })
  })

export const objectKeys = <T extends object>(o: T): (keyof T)[] =>
  Object.keys(o) as (keyof T)[]
