import { useRouter } from 'next/router'
import * as React from 'react'

import { useVersionCheck } from '~shared/hooks/useVersionCheck'

const UpgradeChecker = (): null => {
  const router = useRouter()
  const upgradeCheck = useVersionCheck()

  React.useEffect(() => {
    const fn = () => upgradeCheck()
    router.events.on('routeChangeComplete', fn)
    return () => router.events.off('routeChangeComplete', fn)
  })

  return null
}

export default React.memo(UpgradeChecker)
