import { QueryResult } from '@apollo/client'
import {
  GetInitialUserDataQuery,
  GetInitialUserDataQueryVariables,
  Permission,
  useGetInitialUserDataQuery,
} from '@wise/graphql'
import { isDefined } from '@wise/utils'
import Router from 'next/router'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { route } from '~generated/routes'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import { ALLOWED_USER_TYPES } from '~shared/consts/auth'
import { useMaintenanceContext } from '~shared/hooks/useMaintenanceMode'
import { isWiseUser } from '~shared/permissions/hooks'
import {
  setAnalyticsUser,
  trackAnalyticsEvent,
} from '~shared/services/analytics/analytics'
import { setApiMetadata } from '~shared/services/api/api'
import { setUser } from '~shared/services/bugsnag/client'

import { getDriverName } from '@/subcontractors/tables/helpers'
import { LoginForm } from '@/users/validation/login'

import { getUserErrorMessage } from '../error'
import { AuthResult, AuthResultStatus, AuthUser } from '../types'

import { Authenticator, FirebaseUser } from './Authenticator'
import useAuthStore from './store'

export const attemptLogin = async (
  loginForm: LoginForm,
): Promise<AuthResult> => {
  try {
    const result = await Authenticator.signIn(
      loginForm.email,
      loginForm.password,
      loginForm.rememberMe,
    )

    return { status: AuthResultStatus.SUCCESS, user: result.user }
  } catch (err) {
    return {
      message: getUserErrorMessage(err),
      status: AuthResultStatus.FAILURE,
    }
  }
}

export interface GuestState {
  status: 'undetermined' | 'guest'
}

export interface AuthenticatedUserState {
  status: 'authenticated'
  sessionToken: string
  firebaseUser: FirebaseUser
  user: AuthUser
  userPermissions: Permission[] | undefined
  mcOnboardingData: GetInitialUserDataQuery['getMcOnboardingData'] | null
}

export type AuthenticatorState = AuthenticatedUserState | GuestState

const initialAuthState: AuthenticatorState = {
  status: 'undetermined',
}

export const AuthContext = React.createContext<
  AuthenticatorState & {
    refetchUserData: QueryResult<
      GetInitialUserDataQuery,
      GetInitialUserDataQueryVariables
    >['refetch']
  }
>({
  ...initialAuthState,
  refetchUserData: async () => {
    throw new Error('AuthContext not initialized')
  },
})

export const AuthProvider: React.FC = ({ children }) => {
  const { t } = useTranslation()
  const maintenanceStatus = useMaintenanceContext().status
  const store = useAuthStore()

  const { data: initialUserData, refetch: refetchUserData } =
    useGetInitialUserDataQuery(
      store.userId
        ? {
            errorPolicy: 'ignore',
            fetchPolicy: 'network-only',
            onCompleted: async (data) => {
              const user = data.me.user ?? undefined
              if (!user) {
                // Something bad has happened, logout, redirect & show error modal.
                await Authenticator.logout()
                triggerErrorModal({
                  error: new Error(t('login.login_error')),
                })
                return
              }
              trackAnalyticsEvent('user_session_started', {
                auth_user_id: user.id ?? '-',
                mode: isWiseUser(user) ? 'WAP' : 'MCP',
                role: user.roles?.find(isDefined)?.title ?? 'unknown',
              })
            },
          }
        : { skip: true },
    )

  React.useEffect(() => {
    // Listen to firebase auth session state changes
    const unsubscribe = Authenticator.onSessionTokenChange(
      async (firebaseUser) => {
        const sessionToken = await Authenticator.getSessionToken()
        const userId = firebaseUser?.uid

        useAuthStore.getState().set({
          sessionToken,
          userId,
          firebaseUser: firebaseUser ?? undefined,
        })
      },
    )

    // Refresh token if returning to the tab, as we MAY have missed a token change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        Authenticator.refreshToken()
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      unsubscribe?.()
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const authenticatorState = React.useMemo<AuthenticatorState>(() => {
    try {
      if (store.lastAuthUpdate === undefined) return { status: 'undetermined' }

      const sessionToken = store.sessionToken
      const firebaseUser = store.firebaseUser

      const data =
        initialUserData?.me.user.id === store.userId
          ? initialUserData
          : undefined

      const user = data?.me.user
      const mcOnboardingData = data?.getMcOnboardingData
      const userPermissions =
        data?.me.permissions.map((permission) => permission.permission) ??
        undefined

      const isAllowed =
        isDefined(user) && ALLOWED_USER_TYPES.includes(user.type)

      if (firebaseUser && user && sessionToken && !isAllowed) {
        throw new Error('You do not have permission to login.')
      }

      if (!sessionToken || !firebaseUser) {
        return { status: 'guest' }
      }

      if (sessionToken && firebaseUser && user && userPermissions) {
        return {
          status: 'authenticated',
          firebaseUser,
          sessionToken,
          user,
          userPermissions,
          mcOnboardingData,
        }
      }

      return { status: 'undetermined' }
    } catch (error) {
      if (maintenanceStatus === 'in-maintenance') return { status: 'guest' }
      Authenticator.logout().then(() => Router.replace(route('/login')))
      triggerErrorModal({
        id: 'auth_error',
        title: 'Uh, oh!',
        description:
          'There was an unexpected error with your user session, please try logging in again.',
        error,
      })
      return { status: 'guest' }
    }
  }, [
    initialUserData,
    maintenanceStatus,
    store.firebaseUser,
    store.lastAuthUpdate,
    store.sessionToken,
    store.userId,
  ])

  const valueUser = React.useMemo(
    () => ('user' in authenticatorState ? authenticatorState.user : undefined),
    [authenticatorState],
  )
  React.useEffect(() => {
    if (valueUser === undefined)
      return void setUser(undefined, undefined, undefined)

    // Otherwise set info
    setAnalyticsUser(valueUser)
    setUser(valueUser.id, valueUser.email, getDriverName(valueUser))
    setApiMetadata(valueUser)
  }, [valueUser])

  const value = React.useMemo(
    () => ({ ...authenticatorState, refetchUserData }),
    [authenticatorState, refetchUserData],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
