import toast, { ToastPosition } from 'react-hot-toast'

import { castArray } from './array'

type Description = string | JSX.Element | null

interface BaseToast<T extends string> {
  type: T
  id?: string
  description: Description
  duration?: number
  position?: ToastPosition
}

type ToastOptions = BaseToast<'success' | 'info' | 'error' | 'custom'>

const DEFAULT_DURATION = 4000 // ms

export const showToastNotification = ({
  type,
  description,
  ...options
}: ToastOptions) => {
  switch (type) {
    case 'success':
      return toast.success(() => description, {
        ...options,
        duration: options.duration ?? DEFAULT_DURATION,
      })
    case 'error':
      return toast.error(() => description, {
        ...options,
        duration: options.duration ?? DEFAULT_DURATION,
      })
    case 'info':
      return toast(() => description, {
        ...options,
        icon: 'ℹ️',
        duration: options.duration ?? DEFAULT_DURATION,
      })
    case 'custom':
      return toast(() => description, {
        ...options,
        style: { maxWidth: 'unset' },
      })
  }
}

export const removeToast = (toastId: string | string[]): void => {
  return castArray(toastId).forEach((id) => toast.dismiss(id))
}
