import { MainContractorStatus } from '@wise/graphql'
import { invariant } from '@wise/utils'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions & {
  status: MainContractorStatus
}

export const mcStatus = (options?: Options): MiddlewareHandler => ({
  fn: ({ mode, mainContractor }) => {
    invariant(options?.status, 'Status is required')
    return mode === 'WAP' || mainContractor?.status === options.status
      ? { status: 'ok' }
      : { status: 'redirect', url: options.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
