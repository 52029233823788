import { isDefined } from './array'

const optionIndex = (list: Option[], item: Option): number => {
  return list.findIndex((option) => option.value === item.value)
}

export const isOptionPresent = (list: Option[], item: Option): boolean =>
  optionIndex(list, item) !== -1

export const toggleOption = (list: Option[], item: Option): Option[] => {
  const newList = [...list]
  const ix = optionIndex(list, item)
  ix === -1 ? newList.push(item) : newList.splice(ix, 1)
  return newList
}

export const toOptionList = <T>({
  items,
  value,
  label,
  sort = true,
}: {
  items: (Mandatory<T> | null | undefined)[] | null | undefined
  value:
    | keyof Mandatory<T>
    | ((i: Mandatory<T>) => Option['value'] | null | undefined)
  label:
    | keyof Mandatory<T>
    | ((i: Mandatory<T>) => Option['label'] | null | undefined)
  sort?: boolean
}): Option<{ item: T }>[] => {
  if (!items || items.length === 0) return []

  const opts: Option<{ item: T }>[] = []

  items.forEach((item) => {
    if (!item) return
    const val = typeof value === 'function' ? value(item) : item[value]
    const lbl = typeof label === 'function' ? label(item) : item[label]
    if (!isDefined(val) || !isDefined(lbl)) return
    opts.push({
      value: val as Option['value'],
      label: lbl as Option['label'],
      item,
    })
  })

  if (sort) {
    opts.sort((a, b) => (b.label > a.label ? -1 : 1))
  }

  return opts
}
