import {
  useAcceptModulrPaymentTermsMutation,
  useGetModulrPaymentStatusForMainContractorQuery,
} from '@wise/graphql'
import { isNonEmptyString, showToastNotification } from '@wise/utils'
import * as React from 'react'

import ConfirmModal from '~shared/components/Modal/preset/ConfirmModal/ConfirmModal'
import { triggerErrorModal } from '~shared/components/Modal/preset/GenericModal/GenericModal'
import {
  pushLoadingModal,
  pushModal,
  removeModal,
} from '~shared/components/Modal/useModal'
import { createComponent } from '~shared/utils/createComponent'

import { MODULR_TERMS_AND_CONDITIONS_PATH } from '../consts/terms'

interface Props {
  mainContractorId: Optional<string>
}

export type ModulrPaymentInfo = ReturnType<typeof useModulrPayments>
export const useModulrPayments = ({ mainContractorId }: Props) => {
  const { data, error, loading, called, refetch } =
    useGetModulrPaymentStatusForMainContractorQuery(
      isNonEmptyString(mainContractorId)
        ? {
            variables: {
              id: mainContractorId,
              mcId: mainContractorId,
            },
          }
        : { skip: true },
    )

  const [acceptTermsMutation] = useAcceptModulrPaymentTermsMutation()

  const { isEnabled, hasSignedTerms } = React.useMemo(() => {
    const isEnabled = data?.isModulrEnabled ?? false
    const hasSignedTerms = data?.mainContractor?.modulr?.tCsAccepted ?? false
    return { isEnabled, hasSignedTerms }
  }, [data?.isModulrEnabled, data?.mainContractor?.modulr?.tCsAccepted])

  const acceptTermsConfirm = React.useCallback(
    async (onSuccess?: () => void) => {
      if (!mainContractorId) return
      try {
        pushLoadingModal('accept-modulr-terms', 'Accepting terms...')
        await acceptTermsMutation({
          variables: {
            mcId: mainContractorId,
          },
        })
        await refetch()
        showToastNotification({
          type: 'success',
          description: 'Modulr terms and conditions accepted',
        })
        onSuccess?.()
      } catch (error) {
        triggerErrorModal({ error })
      } finally {
        removeModal('accept-modulr-terms')
      }
    },
    [acceptTermsMutation, mainContractorId, refetch],
  )

  const acceptTerms = React.useCallback(
    (onSuccess?: () => void) => {
      try {
        pushModal({
          id: 'accept-modulr-terms-and-conditions-confirmation',
          component: createComponent(ConfirmModal, {
            confirmButtonContent: 'Confirm.',
            onConfirm: () => acceptTermsConfirm(onSuccess),
            title: 'Confirm.',
            description: (
              <>
                Please confirm you have read the{' '}
                <a
                  className='font-bold text-purple underline'
                  target='_blank'
                  rel='noopener noreferrer'
                  href={MODULR_TERMS_AND_CONDITIONS_PATH}
                >
                  Modulr Terms & Conditions
                </a>{' '}
                and agree to them.
              </>
            ),
          }),
        })
      } catch (error) {
        triggerErrorModal({ error })
      }
    },
    [acceptTermsConfirm],
  )

  return {
    acceptTerms,
    isEnabled,
    hasSignedTerms,
    error,
    loading,
    called,
  }
}
