import * as React from 'react'

const colorMap = {
  black: '#000000',
  purple: '#663399',
  white: '#ffffff',
  darkGrey: '#caced5',
}

interface Props {
  color?: keyof typeof colorMap | 'theme'
  testId?: string
}

const WithWiseLogo = ({ color = 'white', testId }: Props): JSX.Element => {
  return (
    <svg
      data-testid={testId}
      viewBox='0 0 545 175'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={color === 'theme' ? 'text-theme' : ''}
      style={{ color: color === 'theme' ? '' : colorMap[color] }}
    >
      <path
        d='M0 50H40L64.75 126.25H65.25L86 50H127L149.5 126.25H150L172.75 50H210.75L167.5 171.5H130.5L105.25 90.75H104.75L82.25 171.5H44.5L0 50Z'
        fill='currentColor'
      ></path>
      <path
        d='M214 21.75C214 15.9815 216.292 10.4493 220.37 6.37043C224.449 2.29151 229.982 0 235.75 0C241.518 0 247.051 2.29151 251.13 6.37043C255.208 10.4493 257.5 15.9815 257.5 21.75C257.5 27.5185 255.208 33.0507 251.13 37.1296C247.051 41.2085 241.518 43.5 235.75 43.5C229.982 43.5 224.449 41.2085 220.37 37.1296C216.292 33.0507 214 27.5185 214 21.75V21.75ZM217 50H254.5V171.5H217V50Z'
        fill='currentColor'
      ></path>
      <path
        d='M336.5 85.75C331 78.9167 323.75 75.5 314.75 75.5C311.533 75.5119 308.363 76.2828 305.5 77.75C302.5 79.25 301 81.8333 301 85.5C301 88.5 302.54 90.71 305.62 92.13C309.399 93.7762 313.336 95.0327 317.37 95.88C322.123 96.96 327.207 98.1267 332.62 99.38C337.957 100.6 343.092 102.577 347.87 105.25C352.593 107.895 356.62 111.624 359.62 116.13C362.7 120.71 364.243 126.833 364.25 134.5C364.25 142.34 362.54 148.8 359.12 153.88C355.749 158.919 351.258 163.112 346 166.13C340.412 169.302 334.325 171.5 328 172.63C321.489 173.864 314.877 174.49 308.25 174.5C299.542 174.47 290.879 173.252 282.5 170.88C274.142 168.644 266.541 164.194 260.5 158L283.25 132.75C286.413 136.787 290.367 140.136 294.87 142.59C299.477 144.922 304.588 146.079 309.75 145.96C313.743 146.029 317.717 145.41 321.5 144.13C325 142.883 326.75 140.59 326.75 137.25C326.75 134.09 325.2 131.71 322.12 130.13C318.382 128.319 314.436 126.976 310.37 126.13C305.623 125.05 300.54 123.883 295.12 122.63C289.815 121.42 284.689 119.527 279.87 117C275.123 114.504 271.082 110.851 268.12 106.38C265.04 101.8 263.5 95.6733 263.5 88C263.5 80.84 264.957 74.6733 267.87 69.5C270.697 64.4405 274.628 60.0827 279.37 56.75C284.338 53.3197 289.888 50.8223 295.75 49.38C301.874 47.7962 308.174 46.9965 314.5 47C322.707 47.0315 330.869 48.2095 338.75 50.5C346.678 52.6955 353.853 57.0182 359.5 63L336.5 85.75Z'
        fill='currentColor'
      ></path>
      <path
        d='M487.25 150.5C481.23 158.185 473.419 164.279 464.5 168.25C455.563 172.367 445.84 174.5 436 174.5C427.011 174.56 418.081 173.037 409.62 170C401.669 167.181 394.361 162.806 388.12 157.13C381.992 151.483 377.1 144.63 373.75 137C370.158 128.721 368.369 119.773 368.5 110.75C368.371 101.727 370.16 92.7794 373.75 84.5C377.102 76.8748 381.995 70.0248 388.12 64.38C394.358 58.6979 401.667 54.319 409.62 51.5C418.081 48.4633 427.011 46.9401 436 47C444.096 46.8909 452.131 48.4216 459.62 51.5C466.521 54.3699 472.677 58.7748 477.62 64.38C482.711 70.2649 486.58 77.1045 489 84.5C491.762 92.9719 493.113 101.84 493 110.75V122.5H406C407.212 129.14 410.651 135.168 415.75 139.59C420.75 143.843 426.917 145.967 434.25 145.96C440.41 145.96 445.617 144.587 449.87 141.84C454.203 139.012 457.976 135.409 461 131.21L487.25 150.5ZM459.5 98C459.626 94.9733 459.099 91.9549 457.953 89.1506C456.807 86.3463 455.07 83.8221 452.86 81.75C448.26 77.25 442.333 75 435.08 75C431.099 74.9245 427.139 75.6031 423.41 77C420.263 78.2068 417.344 79.9417 414.78 82.13C412.482 84.1182 410.606 86.5471 409.263 89.2729C407.919 91.9988 407.136 94.9663 406.96 98H459.5Z'
        fill='currentColor'
      ></path>
      <path
        d='M498.99 151.87C498.979 148.784 499.573 145.727 500.74 142.87C501.853 140.105 503.511 137.593 505.615 135.483C507.72 133.374 510.228 131.709 512.99 130.59C518.763 128.255 525.217 128.255 530.99 130.59C533.749 131.711 536.258 133.37 538.37 135.47C540.47 137.576 542.126 140.082 543.24 142.84C545.575 148.613 545.575 155.067 543.24 160.84C542.133 163.605 540.477 166.116 538.371 168.222C536.266 170.327 533.755 171.983 530.99 173.09C525.217 175.425 518.763 175.425 512.99 173.09C510.229 171.978 507.72 170.32 505.615 168.215C503.51 166.11 501.852 163.601 500.74 160.84C499.575 157.993 498.981 154.946 498.99 151.87Z'
        fill='currentColor'
      ></path>
    </svg>
  )
}

export default React.memo(WithWiseLogo)
