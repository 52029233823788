import { notify } from '~shared/services/bugsnag/client'
import { isClientSide } from '~shared/services/context'

type UnknownObject = Record<string, unknown>

const uint8tostring = (u8a: Uint8Array): string => {
  const CHUNK_SZ = 0x1000
  const c = []
  for (let i = 0; i < u8a.length; i += CHUNK_SZ) {
    c.push(
      String.fromCharCode.apply(
        null,
        u8a.subarray(i, i + CHUNK_SZ) as unknown as number[],
      ),
    )
  }
  return c.join('')
}

const uint16tostring = (u16a: Uint16Array): string => {
  const CHUNK_SZ = 0x1000
  const c = []
  for (let i = 0; i < u16a.length; i += CHUNK_SZ) {
    c.push(
      String.fromCharCode.apply(
        null,
        u16a.subarray(i, i + CHUNK_SZ) as unknown as number[],
      ),
    )
  }
  return c.join('')
}

export const toBinaryString = (utf16: string): string => {
  const codeUnits = new Uint16Array(utf16.length)
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = utf16.charCodeAt(i)
  }
  return uint8tostring(new Uint8Array(codeUnits.buffer))
}

export const fromBinaryString = (binary: string): string => {
  const bytes = new Uint8Array(binary.length)
  for (let i = 0; i < bytes.length; ++i) {
    bytes[i] = binary.charCodeAt(i)
  }
  return uint16tostring(new Uint16Array(bytes.buffer))
}

const isomorphicDecodeBase64 = (
  base64: string,
  safeDecode = false,
): string | undefined => {
  try {
    const decoded = isClientSide()
      ? atob(base64)
      : Buffer.from(base64, 'base64').toString('utf-8')

    return safeDecode ? fromBinaryString(decoded) : decoded
  } catch (err) {
    console.warn(err)
    notify(err)
    return undefined
  }
}

const isomorphicEncodeBase64 = (
  utf16: string,
  safeEncode = false,
): string | undefined => {
  try {
    const str = safeEncode ? toBinaryString(utf16) : utf16
    return isClientSide()
      ? btoa(str)
      : Buffer.from(str, 'utf-8').toString('base64')
  } catch (err) {
    console.warn(err)
    notify(err)
    return undefined
  }
}

export const decodeBase64ToString = (
  base64: string,
  safeDecode = false,
): string | undefined => isomorphicDecodeBase64(base64, safeDecode)

export const decodeBase64ToObject = <T = UnknownObject>(
  base64: string,
  safeDecode = false,
): T | undefined => {
  const str = decodeBase64ToString(base64, safeDecode)
  try {
    if (!str) return undefined
    return JSON.parse(str) as T
  } catch (err) {
    if (!safeDecode) return decodeBase64ToObject(base64, true)
    console.warn(err)
    return undefined
  }
}

export const encodeBase64ToString = (
  utf8: string,
  safeEncode = false,
): string | undefined => isomorphicEncodeBase64(utf8, safeEncode)

export const encodeBase64ToObject = <T>(
  object: T,
  safeEncode = false,
): string | undefined =>
  isomorphicEncodeBase64(JSON.stringify(object), safeEncode)

export const stripMimeFromString = (content: string) => {
  return content.replace(/^data:([^;]+);base64,/, '')
}

export const fileToBase64String = (
  file: File,
  stripMime = false,
): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (typeof reader.result === 'string')
        return resolve(
          stripMime ? stripMimeFromString(reader.result) : reader.result,
        )
      return reject('Incorrect data format from reader.result')
    }
    reader.onerror = (error) => reject(error)
    reader.readAsDataURL(file)
  })

export const stripDataHeader = (value: string): Optional<string> => {
  const dfRegex = /data:(?:.+);base64,(.+)/
  if (!value) return null
  const result = dfRegex.exec(value)
  if (!result) return null
  return result[1]
}

export const buildDataUrl = (base64: string, mimeType: string): string => {
  return `data:${mimeType};base64,${base64}`
}
