const CHARSET =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_'

export const randomString = (length: number = 21): string => {
  if (length <= 0) throw new Error('length must be at least 1')
  if (length > 64) throw new Error('length must be at most 64')
  if (!Number.isInteger(length)) throw new Error('length must be an integer')

  let result = ''
  const charactersLength = CHARSET.length
  for (let i = 0; i < length; i++) {
    result += CHARSET.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}
