import type { ParsedFeatureFlags } from '@wise/kv'
import React, { type PropsWithChildren, createContext } from 'react'

import { FALLBACK_FEATURE_FLAGS } from '~shared/config/feature-flags'
import { useMode } from '~shared/hooks/useMode'
import { callLocalAPI } from '~shared/services/api/api'
import { notify } from '~shared/services/bugsnag/client'
import { useUser } from '~shared/services/firebase/auth/hooks'

import { useMaincontractor } from '@/maincontractors/hooks/useMaincontractor'

import { REDUCER_ACTION, featureFlagReducer } from './reducer'

export const FeatureFlagContext = createContext({
  fetchFeatureFlags: () => Promise.resolve(),
  flags: FALLBACK_FEATURE_FLAGS,
})

export const FeatureFlagProvider = (props: PropsWithChildren<object>) => {
  const [state, dispatch] = React.useReducer(
    featureFlagReducer,
    FALLBACK_FEATURE_FLAGS,
  )

  const mainContractorId = useMaincontractor()?.id || ''
  const user = useUser()
  const mode = useMode()

  const fetchFeatureFlags = React.useCallback(async () => {
    try {
      const search = new URLSearchParams({
        mcId: mainContractorId || '',
        portalMode: mode,
        userEmail: user?.email || '',
        userId: user?.id || '',
      }).toString()
      const response = await callLocalAPI<ParsedFeatureFlags>(
        `/api/feature-flags?${search}`,
      )

      if (!response.ok) {
        throw response.error
      }

      dispatch({
        type: REDUCER_ACTION.setFeatureFlags,
        data: response.data,
      })
    } catch (e) {
      notify(e)
      console.error(e)
    }
  }, [mainContractorId, mode, user])

  React.useEffect(() => {
    ;(async () => {
      await fetchFeatureFlags()
    })()
  }, [fetchFeatureFlags])

  return (
    <FeatureFlagContext.Provider value={{ fetchFeatureFlags, flags: state }}>
      {props.children}
    </FeatureFlagContext.Provider>
  )
}
