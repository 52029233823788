import * as React from 'react'

import useTheme from '~shared/hooks/useTheme'

import Favicons from '../Favicons/Favicons'
import Head from '../Head/Head'

const ThemeProvider: React.FC = ({ children }) => {
  const theme = useTheme()

  React.useEffect(() => {
    document.body.setAttribute('data-theme', theme)
  }, [theme])

  const themeColor = React.useMemo(() => {
    switch (theme) {
      case 'green':
        return '#009768'
      case 'purple':
      default:
        return '#663399'
    }
  }, [theme])

  return (
    <>
      <Head>
        <Favicons />
        <meta name='theme-color' content={themeColor} />
      </Head>
      {children}
    </>
  )
}

export default ThemeProvider
