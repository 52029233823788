import { GetInitialUserDataQuery } from '@wise/graphql'

import { FirebaseUser } from './auth/Authenticator'

export enum AuthResultStatus {
  SUCCESS,
  FAILURE,
}

export type AuthUser = Mandatory<GetInitialUserDataQuery['me']['user']>

export interface AuthSuccess {
  status: AuthResultStatus.SUCCESS
  user: FirebaseUser | null
}

export interface AuthError {
  status: AuthResultStatus.FAILURE
  message: string
}

export type AuthResult = AuthSuccess | AuthError

export type FirebaseAuthActionMode =
  | 'resetPassword'
  | 'recoverEmail'
  | 'verifyEmail'
