import { djs } from '@wise/utils'
import produce from 'immer'
import * as React from 'react'
import create from 'zustand'

import { notify } from '~shared/services/bugsnag/client'
import { isServerSide } from '~shared/services/context'
import { Time } from '~shared/utils/Time'

type Props = {
  // The key to use to identify the timeout
  key: string
  // Whether the timeout should persist across browser sessions
  persist?: boolean
}

type TimeoutStore = {
  timeouts: {
    key: string
    ms: number
    start: string // ISO DateTime string
    end: string // ISO DateTime string
  }[]

  addTimeout: (key: string, ms: number) => void
  removeTimeout: (key: string) => void
}

const useTimeoutStore = create<TimeoutStore>((set) => ({
  timeouts: [],

  addTimeout(key, ms) {
    const start = djs().toISOString()
    const end = djs().add(ms, 'ms').toISOString()
    const timeout: TimeoutStore['timeouts'][number] = {
      key,
      ms,
      start,
      end,
    }

    set((state) =>
      produce(state, (draft) => {
        const existingIx = draft.timeouts.findIndex((t) => t.key === key)
        if (existingIx === -1) {
          draft.timeouts.push(timeout)
        } else {
          draft.timeouts[existingIx] = timeout
        }
      }),
    )
  },

  removeTimeout: (key) =>
    set((state) =>
      produce(state, (draft) => {
        const ix = draft.timeouts.findIndex((t) => t.key === key)
        if (ix === -1) return
        draft.timeouts.splice(ix, 1)
      }),
    ),
}))

useTimeoutStore.subscribe((state) => {
  if (isServerSide()) return
  localStorage.setItem('useTimeoutStore', JSON.stringify(state))
})

const removeExpiredTimeouts = () => {
  const now = djs()
  const expiredTimeouts = useTimeoutStore
    .getState()
    .timeouts.filter((t) => now.isAfter(t.end))
  expiredTimeouts.forEach((t) =>
    useTimeoutStore.getState().removeTimeout(t.key),
  )
}

const syncStore = () => {
  if (isServerSide()) return
  try {
    const data = localStorage.getItem('useTimeoutStore')
    if (!data) return
    const json = JSON.parse(data)
    useTimeoutStore.setState(json)
  } catch (error) {
    notify(error)
  }
}

/**
 *
 */
export const useTimeout = ({ key }: Props) => {
  const ok = useTimeoutStore((state) => {
    const hasTimeout = state.timeouts.find((t) => t.key === key)
    if (!hasTimeout) return true
    const isExpired = djs().isAfter(hasTimeout.end)
    if (isExpired) return true
    return false
  })

  const addTimeout = useTimeoutStore((state) => state.addTimeout)

  const setTimeout = (time: Time) => addTimeout(key, time.toMs())

  React.useEffect(() => {
    if (isServerSide()) return
    syncStore()
    document.addEventListener('visibilitychange', syncStore)
    window.addEventListener('focus', syncStore)
    return () => {
      document.removeEventListener('visibilitychange', syncStore)
      window.removeEventListener('focus', syncStore)
    }
  })

  React.useEffect(() => {
    if (isServerSide()) return
    if (ok) return
    const interval = setInterval(() => removeExpiredTimeouts(), 1000)
    return () => clearInterval(interval)
  }, [ok])

  return [ok, setTimeout] as const
}

useTimeout.clearAll = () => useTimeoutStore.setState({ timeouts: [] })
