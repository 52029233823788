import * as React from 'react'

import { AuthenticatedUserState } from '~shared/services/firebase/auth/context'
import { useUser } from '~shared/services/firebase/auth/hooks'
import { AuthUser } from '~shared/services/firebase/types'

import {
  PermissionCheckType,
  hasPermission,
  isRawPermission,
  parsePermission,
} from './permissions'
import { Permission } from './types'

type UseHasPermissionCallback = (
  query: Parameters<typeof hasPermission>[1],
  mode?: PermissionCheckType,
) => boolean

const isString = (maybe: Optional<string>): maybe is string =>
  typeof maybe === 'string'

const parseRawPermissions = (
  rawPermissions: AuthUser['permissions'],
): Permission[] => {
  if (!rawPermissions) return []
  const permissions = rawPermissions
    .filter(isString) // It must be a string
    .filter(isRawPermission) // It must be a raw permission
    .map(parsePermission) // Map to a permission
  return permissions
}

export const useHasPermission = (): UseHasPermissionCallback => {
  const rawPermissions = useUser()?.permissions
  return React.useCallback(
    (query, mode) => {
      const permissions = parseRawPermissions(rawPermissions)
      return hasPermission(permissions, query, mode)
    },
    [rawPermissions],
  )
}

/**
 * Non-callback variant of the permission hook, that returns a boolean
 * indicating whether the permission is currently available within the users
 * roles/permissions list.
 * @param query The permission query to execute
 */
export const usePermission: UseHasPermissionCallback = (query, mode) => {
  const hasPermission = useHasPermission()
  return React.useMemo(
    () => hasPermission(query, mode),
    [hasPermission, mode, query],
  )
}

export const isWiseUser = (
  user: AuthenticatedUserState['user'] | undefined,
): boolean => user?.type === 'WISE'

/**
 * This will ALWAYS return `true` for a Wise user - even if they are
 * in 'MCP mode'
 */
export const useIsWiseUser = (): boolean => {
  const user = useUser()

  return user ? isWiseUser(user) : false
}
