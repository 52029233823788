import cx from 'classnames'
import * as React from 'react'

interface Props {
  onIntercept: () => void
  disabled?: boolean
}

const ClickInterceptor: React.FC<Props & JSX.IntrinsicElements['div']> = ({
  onClick,
  onIntercept,
  className,
  children,
  disabled,
  ...props
}) => {
  const ref = React.useRef<HTMLDivElement>(null)

  return (
    <div
      {...props}
      ref={ref}
      className={cx('absolute left-0 top-0 right-0 bottom-0', className)}
      onClick={(e) => {
        onClick?.(e)
        if (disabled) return
        const clickedOverlay = e.target === ref.current
        if (clickedOverlay) onIntercept()
      }}
    >
      {children}
    </div>
  )
}

export default ClickInterceptor
