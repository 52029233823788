import { isUndefined, omitBy } from 'lodash'
import * as React from 'react'
import SocketIO from 'socket.io-client'

import { WEBSOCKET_ENDPOINT } from '~shared/config/websocket'
import { createLogger } from '~shared/utils/log'

import { getMainContractorFromUser } from '@/maincontractors/hooks/useMaincontractor'

import { notify } from '../bugsnag/client'
import { isServerSide } from '../context'
import { useAuth } from '../firebase/auth/hooks'

import { SocketEvent } from './events'
import { AsyncDownloads } from './new-toast/async-download'
import { NewToast } from './new-toast/handler'
import { WorklogEvents } from './new-toast/worklog'

const log = createLogger('ws')

type CreateSocketOptions = {
  sessionToken: string
  mainContractorId: string | undefined
}

const createSocket = (socketOptions: CreateSocketOptions) => {
  return SocketIO(WEBSOCKET_ENDPOINT, {
    autoConnect: false,
    reconnection: true,
    reconnectionDelay: 5000,
    query: omitBy(
      {
        mcID: socketOptions.mainContractorId,
        authToken: socketOptions.sessionToken,
      },
      isUndefined,
    ),
    transports: ['websocket'],
  })
}

const SocketContext = React.createContext<ReturnType<
  typeof createSocket
> | null>(null)

type Props = {
  children: React.ReactNode
}

export const useSocket = () => React.useContext(SocketContext)

export const SocketProvider = ({ children }: Props) => {
  const auth = useAuth()

  const sessionToken =
    auth.status === 'authenticated' ? auth.sessionToken : null
  const user = auth.status === 'authenticated' ? auth.user : null
  const mainContractorId = user
    ? getMainContractorFromUser(user)?.id ?? undefined
    : undefined

  const socket = React.useMemo(() => {
    // Don't connect if we're on the server side
    if (isServerSide()) return null

    // Don't connect if we don't have a token
    if (!sessionToken) return null

    log('Creating socket', {
      token: sessionToken.slice(0, 6) + '...',
      mcID: mainContractorId,
    })

    return createSocket({
      sessionToken: sessionToken,
      mainContractorId,
    })
  }, [mainContractorId, sessionToken])

  React.useEffect(() => {
    try {
      if (!socket) return
      log('Connecting...')

      socket.on('connect', () => log('Connected'))
      socket.on('disconnect', (reason) => {
        log('Disconnected ->', reason)
        AsyncDownloads.onDisconnect()
        WorklogEvents.onDisconnect()
      })

      socket.on(SocketEvent.ASSIGNED_ROOM, (roomId) =>
        log(`Assigned room "${roomId || 'unknown'}"`),
      )
      socket.on(SocketEvent.ASSIGNED_USER_ROOM, (roomId) =>
        log(`Assigned user room "${roomId || 'unknown'}"`),
      )

      socket.on('new-toast', (type, payload) =>
        NewToast.handler(log, type, payload),
      )

      socket.connect()

      return () => {
        try {
          log('Deallocating socket...')
          socket.disconnect()
        } catch (error) {
          log('Error deallocating socket')
          notify(error)
        }
      }
    } catch (error) {
      log('Error running socket-io useEffect')
      notify(error)
    }
  }, [socket])

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  )
}

export const useSocketEvent = (
  event: SocketEvent,
  callback: (...args: unknown[]) => void,
) => {
  const socket = useSocket()
  const callbackRef = React.useRef(callback)

  React.useEffect(() => {
    if (!socket) return

    const callbackFn = callbackRef.current
    const fn = (...args: unknown[]) => {
      log(`Event: "${event}"`)
      callbackFn(...args)
    }

    log(`Registering socket event "${event}"`)
    socket.on(event, fn)

    return () => {
      log('De-registering socket event', event)
      void socket.off(event, fn)
    }
  }, [event, socket])
}

export type SocketStatus =
  | 'connected'
  | 'connecting'
  | 'disconnected'
  | 'inactive'

export const useSocketStatus = (
  socket: ReturnType<typeof createSocket> | null,
): SocketStatus =>
  React.useMemo(() => {
    if (!socket) return 'inactive'
    if (socket.connected) return 'connected'
    if (socket.disconnected) return 'disconnected'
    return 'connecting'
    // Disable deps - as the `socket` reference doesn't change, but the booleans within can/do
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, socket?.connected, socket?.disconnected])
