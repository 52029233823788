import { FeatureFlagName } from '@wise/kv'
import { invariant } from '@wise/utils'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions & {
  featureFlag: FeatureFlagName
  default?: boolean
}

export const featureFlag = (options?: Options): MiddlewareHandler => ({
  fn: ({ featureFlags }) => {
    invariant(options?.featureFlag, 'Feature flag is required')
    const value = featureFlags[options.featureFlag] ?? options.default ?? false
    return value
      ? { status: 'ok' }
      : { status: 'redirect', url: options.redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
