export const translateError = (
  error: Error,
  translateMap: Record<string, string>,
): Error => {
  const message = error.message
  const found = Object.entries(translateMap).find(([key]) => key === message)
  if (found) {
    error.message = found[1]
  }
  return error
}
