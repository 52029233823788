export const castArray = <T>(item: T | T[]): T[] =>
  Array.isArray(item) ? item : [item]

export const isDefined = <T>(item: T): item is Mandatory<T> => {
  return item !== null && item !== undefined
}

export const asArray = <T = unknown>(array: unknown): T[] => {
  if (!Array.isArray(array)) return []
  return array
}
