export class Time {
  private constructor(private ms: number) {}

  public static seconds(seconds: number): Time {
    return new Time(seconds * 1000)
  }

  public static minutes(minutes: number): Time {
    return Time.seconds(60 * minutes)
  }

  public toMs = (): number => this.ms
  public toMilliseconds = () => this.toMs()
}
