import { getRedirectRouteFromQueryParams } from '@wise/utils'
import Router from 'next/router'

import { DefaultOptions, MiddlewareHandler } from '../types'

type Options = DefaultOptions

export const guest = (options?: Options): MiddlewareHandler => ({
  fn: (ctx) => {
    const redirectTo =
      getRedirectRouteFromQueryParams(Router.query) ?? undefined
    return ctx.auth.status === 'guest'
      ? { status: 'ok' }
      : { status: 'redirect', url: options?.redirectTo || redirectTo }
  },
  optimisticRender: options?.optimisticRender ?? false,
  wrapper: options?.wrapper,
})
