import { useAuth, useUser } from '../services/firebase/auth/hooks'

export type MainContractorPortal = 'MCP'
export type WiseAdminPortal = 'WAP'
export type NetworkPortal = 'NAP'

export type PortalMode =
  | MainContractorPortal
  | WiseAdminPortal
  | NetworkPortal
  | 'undetermined'

export const useMode = (): PortalMode => {
  const { status } = useAuth()
  const user = useUser()

  if (status === 'undetermined') return 'undetermined'

  switch (user?.type) {
    case 'NETWORK':
      return 'NAP'
    case 'WISE':
      return 'WAP'
    case 'MAINCONTRACTOR':
    default:
      return 'MCP'
  }
}
